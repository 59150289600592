/* You can add global styles to this file, and also import other style files */
@use "../../goldstar-share/src/app/style/dev-express-theme.css";
@use "../../goldstar-share/src/app/style/style.material.scss";
@use "../../../node_modules/bootstrap/dist/css/bootstrap.css";
//@use "projects/goldstar-share/src/app/style/bootstrap.min.css";
@use "../../../node_modules/bootstrap-icons/font/bootstrap-icons.css";

// PLEASE PORT THESE STYLES INTO COLORS, SOON TO BE REMOVED
@use "../../goldstar-share/src/app/style/style.variable.scss" as var;

@use "../../goldstar-share/src/app/style/style.performance.management.variable.scss" as varperformance;
@use "../../../node_modules/ngx-toastr/toastr.css";
@use "../../../node_modules/devexpress-gantt/dist/dx-gantt.min.css";
@import "../../../node_modules/jsoneditor/dist/jsoneditor.min.css";

@import "../../../node_modules/bpmn-js/dist/assets/bpmn-js.css";
@import "../../../node_modules/diagram-js/assets/diagram-js.css";
@import "../../../node_modules/bpmn-js/dist/assets/bpmn-font/css/bpmn.css";
@import "../../../node_modules/bpmn-js-color-picker/colors/color-picker.css";
@import "../../../node_modules/bpmn-js-properties-panel/dist/assets/properties-panel.css";

@import "../../../node_modules/devexpress-richedit/dist/dx.richedit.css";
@import "../../../node_modules/@devexpress/analytics-core/dist/css/dx-analytics.common.css";
@import "../../../node_modules/@devexpress/analytics-core/dist/css/dx-analytics.light.css";
@import "../../../node_modules/@devexpress/analytics-core/dist/css/dx-querybuilder.css";
@import "../../../node_modules/devexpress-reporting/dist/css/dx-webdocumentviewer.css";
@import "../../../node_modules/devexpress-reporting/dist/css/dx-reportdesigner.css";

// TO BE REMOVED SOON DO NOT USE
@import "./variable.scss";
@import "../../goldstar-share/src/app/style/style.variable.scss";

// DEV EXPRESS DASHBOARD
@import "../../../node_modules/ace-builds/css/ace.css";
@import "../../../node_modules/ace-builds/css/theme/dreamweaver.css";
// @import "node_modules/ace-builds/css/theme/ambiance.css";
// @import "node_modules/devextreme/dist/css/dx.light.css";
// @import '../../../node_modules/@devexpress/analytics-core/dist/css/dx-analytics.common.css';
// @import '../../../node_modules/@devexpress/analytics-core/dist/css/dx-analytics.light.css';
// @import '../../../node_modules/@devexpress/analytics-core/dist/css/dx-querybuilder.css';
@import "../../../node_modules/devexpress-dashboard/dist/css/dx-dashboard.light.css";

// WORKFLOW JOB TASK STATUS COLOR CODE
.completed-task {
	background-color: #007504;
	opacity: 0.4;
	pointer-events: none;
}

.running-task {
	background-color: #3573d2;
	opacity: 0.4;
	pointer-events: none;
}

.plaid-document-verfication-match .dx-texteditor-input {
	color: #007504 !important;
	font-weight: bold;
}

.plaid-document-verfication-not-match .dx-texteditor-input {
	color: #8a7153 !important;
	font-weight: bold;
}

.waiting-task {
	background-color: #ffc107;
	opacity: 0.4;
	pointer-events: none;
}

.failed-task {
	background-color: #de3c4b;
	opacity: 0.4;
	pointer-events: none;
}

.default-task {
	background-color: transparent;
	opacity: 0.4;
	pointer-events: none;
}

html {
	width: 100%;
	height: 100%;
}

body {
	width: 100%;
	height: 100%;
	margin: 0;
	overflow: hidden;
}

.fw-700 {
	font-weight: 700 !important;
}

/* NEEDED TO OVERRIDE GENERIC TIPPY STYLE FOR ALL FEATURE, NEED TO IMPLEMENT CUSTOM TEMPLATE FOR ALL */
.tippy-box {
	background-color: transparent !important;
	border: none !important;
}

/* STYLE NEEDED TO ALTER THE BACKGROUND CONTAINER WHEN TIPPY IS OPENED */
.pos-relative {
	position: relative;
	z-index: -200;
}

.pos-static {
	position: static;
}

.mat-mdc-raised-button:not(:disabled) {
	min-height: 38px;
	border-radius: 5px;
}

app-menu-list-item {
	a {
		height: 30px !important;

		/* TODO(mdc-migration): The following rule targets internal classes of list that may no longer apply for the MDC version. */
		/* TODO(mdc-migration): The following rule targets internal classes of list that may no longer apply for the MDC version. */
		/* TODO(mdc-migration): The following rule targets internal classes of list that may no longer apply for the MDC version. */
		.mat-list-item-content {
			padding: 0 !important;
			color: white;
			font-size: 14px;
		}
	}
}

app-reporting-dashboard {
	height: 100% !important;
	.dx-dashboard-context-menu-panel {
		overflow: scroll !important;
	}

	.dx-dashboard-data-binding-surface .dx-dashboard-data-source-panel.dx-dashboard-data-source-panel-opened {
		min-height: 358px;
	}

	.dx-dashboard-layout-container {
		padding-bottom: 50px;
	}
}

app-reporting-dashboard-view {
	.dx-dashboard-layout-container {
		padding-bottom: 50px;
	}
}

app-cri-community-responsibility-and-involvement-log {
	tr {
		/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
		/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
		/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
		.mat-form-field-wrapper {
			padding-bottom: 0px !important;
		}
	}
}

app-menu-group-list{
	.dx-datagrid .dx-datagrid-content-fixed .dx-datagrid-table .dx-col-fixed{
		background-color: transparent !important;
	}
}

app-menu-group-create{
	.dx-treelist-container .dx-treelist-content-fixed .dx-treelist-table .dx-col-fixed{
		background-color: transparent !important;
	}
}

.flex-container {
	display: flex;
}

.column {
	flex-direction: column;
}

.flex-center-start {
	display: flex;
}

.pointer {
	cursor: pointer;
}

.grabbable {
	cursor: move;
	/* fallback if grab cursor is unsupported */
	cursor: grab;
	cursor: -moz-grab;
	cursor: -webkit-grab;
}

/* (Optional) Apply a "closed-hand" cursor during drag operation. */
.grabbable:active {
	cursor: grabbing;
	cursor: -moz-grabbing;
	cursor: -webkit-grabbing;
}

.heading-content h1 {
	margin: 0;
	font:
		400 24px/32px Roboto,
		"Helvetica Neue",
		sans-serif;
	letter-spacing: normal;
	margin: 0 0 16px;
}

.row {
	flex-direction: row;
}

.fill-remaining-space {
	flex: auto;
}

.btn-grown-color {
	background-color: #8b7054 !important;
	color: white !important;
}

.flex-row {
	display: flex;
	flex-direction: row;
}

.widget-container {
	height: 100%;
	width: 100%;
	margin-top: 5px;
	margin-left: 5px;
	margin-right: 5px;
	overflow-x: auto;
	overflow-y: auto;
}

.flex-center-horizontal {
	align-items: center;
}

.flex-grow-1 {
	flex-grow: 1;
}

.flex-gap-6 {
	gap: 6px;
}

.flex-right-align {
	margin-left: auto;
}

app-training-list {
	height: 100%;
}

app-cri-office-held {
	height: 100%;
}

app-training-event {
	.ngx-timepicker-control__input {
		background: transparent !important;
		border: none !important;
	}

	/* TODO(mdc-migration): The following rule targets internal classes of card that may no longer apply for the MDC version. */
	/* TODO(mdc-migration): The following rule targets internal classes of card that may no longer apply for the MDC version. */
	/* TODO(mdc-migration): The following rule targets internal classes of card that may no longer apply for the MDC version. */
	mat-card.error {
		border: 1px solid red;
	}
}

app-cri-position-type-lu {
	height: 100%;
}

app-cri-purpose-of-service-lu {
	height: 100%;
}

app-cri-user {
	height: 100%;
}

app-cri-community-organization {
	height: 100%;
}

app-training {
	overflow-x: auto !important;
}

app-chat-list {
	position: inherit;
	height: unset !important;
}

app-menu-list-item {
	/* TODO(mdc-migration): The following rule targets internal classes of list that may no longer apply for the MDC version. */
	/* TODO(mdc-migration): The following rule targets internal classes of list that may no longer apply for the MDC version. */
	/* TODO(mdc-migration): The following rule targets internal classes of list that may no longer apply for the MDC version. */
	.mat-list-item-content:hover {
		color: var.$secondary-color !important;
	}
}

app-service-desk-dashboard {
	.dx-menu-items-container {
		height: unset !important;
	}
}

app-referral-dashboard {
	.dx-menu .dx-menu-item-has-text.dx-menu-item-has-submenu .dx-menu-item-text {
		font-size: 1.3rem;
		color: #866b4b;
		font-weight: 500;
	}

	.dx-menu .dx-menu-item-has-text.dx-menu-item-has-submenu .dx-menu-item-text:hover {
		text-decoration: underline;
		color: black;
	}

	.dx-menu-base .dx-menu-item .dx-menu-item-content .dx-icon {
		display: none;
	}

	.dx-menu-horizontal {
		// height: 130% !important;
	}

	.dx-menu-items-container {
		height: unset !important;
	}
}

//dashborad dropdown styles
.dx-menu-base .dx-menu-item .dx-menu-item-content .dx-menu-item-text {
	color: #363b6c;
	//color: #866b4b;
	font-weight: 500;
	font-size: 1.3rem;
	list-style-type: none;
}

.dx-menu-item.dx-state-hover {
	background-color: transparent;
}

.dx-menu .dx-menu-item-expanded {
	background-color: transparent;
}

.dx-context-menu-container-border {
	border: none;
	box-shadow: none;
}

.dx-context-menu-content-delimiter {
	display: none !important;
}

.dx-menu-item.dx-state-focused {
	background-color: transparent;
	color: black;
}

app-data-grid-referral {
	.dx-datagrid-rowsview .dx-select-checkboxes-hidden > tbody > tr > td > .dx-select-checkbox {
		display: inline-block;
	}
}

// Project page design
.transparent-bg {
	background-color: transparent;
}

.primary-text {
	color: $primary-color;
}

.primary-bg {
	background-color: $primary-color;
}

.rounded-corner-btn {
	border-radius: 21px;
	height: 35px;
	font-size: 14px;
	border-color: transparent;
}

.primary-btn-color {
	background-color: var.$primary-btn-color !important;
	color: white !important;
}

.data-grid-title {
	color: var.$primary-color;
	font-size: $medium;
	font-weight: 700;
}

.data-grid-header-container {
	display: flex;
	flex-direction: row;
	justify-content: center;
	margin-right: 40px;
}

.popup-container {
	display: flex;
	flex-direction: column;
	align-items: flex-end;
}

.popup-btn-container {
	display: flex;
	float: right;
	margin-top: 5rem;
}

.cancel-btn-container {
	background-color: var.$secondary-color !important;
	color: var.$white !important;
	margin-left: 1rem;
}

// region for performance module styles

.dx-datagrid-borders .dx-datagrid-rowsview {
	border: none;
}

.btn-performance-approve {
	background-color: varperformance.$trb-peroformance-approve !important;
	color: var.$white !important;
}

.btn-performance-approve:hover {
	color: var.$white !important;
}

.btn-performance-save-yes-open {
	background-color: var.$trb-blue !important;
	color: var.$white !important;
}

.btn-performance-save-yes-open:hover {
	color: var.$white !important;
}

.btn-performance-cancel {
	background-color: varperformance.$trb-secondary-cancel !important;
	color: var.$white !important;
}

.btn-performance-cancel:hover {
	color: var.$white !important;
}

.btn-performance-confirm {
	background-color: varperformance.$trb-performance-confirm !important;
	color: var.$white !important;
}

.btn-performance-confirm:hover {
	color: var.$white;
}

.btn-performance-secondary {
	background-color: varperformance.$trb-performance-no-mark-as-complete !important;
	color: var.$white !important;
}

.btn-performance-secondary:hover {
	color: var.$white !important;
}

.btn-performance-abandon {
	background-color: varperformance.$trb-performance-abandon !important;
	color: var.$white !important;
}

.btn-performance-abandon:hover {
	color: var.$white;
}

.btn-performance-save {
	background-color: varperformance.$trb-performance-save !important;
	color: var.$white !important;
}

.btn-performance-save:hover {
	color: var.$white !important;
}

.btn-performance-approved {
	background-color: #00ce15 !important;
	color: var.$white !important;
}

.btn-performance-approved:hover {
	color: var.$white !important;
}

.bg-performance-primary {
	background-color: varperformance.$trb-performance-save !important;
}

.bg-performance-sucess {
	background-color: varperformance.$trb-performance-completed !important;
}

.bg-performance-abandoned {
	background-color: varperformance.$trb-performance-abandon !important;
}

.performance-management-open-status-cell-backgound {
	background-color: var.$trb-blue !important;
	// text-align: center;
	color: white;
	// border-radius: 20px;
	// max-width: 100px;
}

.performance-management-completed-status-cell-backgound {
	background-color: varperformance.$trb-performance-completed !important;
	// text-align: center;
	color: white;
	// border-radius: 20px;
	// max-width: 100px;
}

.performance-management-abandoned-status-cell-backgound {
	background-color: varperformance.$trb-performance-abandon !important;
	// text-align: center;
	color: white;
	// border-radius: 20px;
	// max-width: 100px;
}

.performance-management-open-status-ellipse {
	background-color: var.$trb-blue !important;
	text-align: center;
	color: white;
	border-radius: 20px;
	max-width: 100px;
}

.performance-management-completed-status-ellipse {
	background-color: varperformance.$trb-performance-completed !important;
	text-align: center;
	color: white;
	border-radius: 20px;
	max-width: 100px;
}

.performance-management-abandoned-status-ellipse {
	background-color: varperformance.$trb-performance-abandon !important;
	text-align: center;
	color: white;
	border-radius: 20px;
	max-width: 100px;
}

.app-referral-comment {
	.app-data-grid {
		height: 280px !important;
	}
}

.data-grid-user-row-color {
	color: green;
}

.data-grid-manager-row-color {
	color: red;
}

// end region

// < gp stuff >
/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
.mat-form-field-wrapper {
	padding: 0 !important;
	margin: 0 !important;
}

th.mat-mdc-header-cell:first-of-type,
td.mat-mdc-cell:first-of-type,
td.mat-mdc-footer-cell:first-of-type {
	padding: 0px !important;
}

// </ gp stuff >
.b-percentdone-circle {
	margin: 17px !important;
}

/* auto fit columns */
.grid-container {
	display: grid;
	grid-gap: 10px;
}

.grid-container--fit {
	grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
	grid-auto-flow: dense;
}

.grid-container--fit .width-max {
	grid-column-end: span 3;
}

.grid-container--fit .width-mid {
	grid-column-end: span 2;
}

.grid-container--fit .width-max-high {
	grid-column-end: span 4;
}

.btn-performance-mark-as-complete {
	background-color: #17a2b8;
	color: white;
	border: none;
}

.backdropBackground {
	filter: blur(20px);
	background-color: lightgrey;
	opacity: 0.9 !important;
}

/* Referrals Module css */
.dx-texteditor.dx-editor-outlined {
	padding: 0px 0px !important;
	border-radius: 0.375rem !important;
	transition:
		border-color 0.15s ease-in-out,
		box-shadow 0.15s ease-in-out;
	height: 34px;
}

.dx-editor-outlined.dx-texteditor-with-label .dx-texteditor-label .dx-label span {
	font-size: 14px;
}

.dx-label {
	color: #484545 !important;
	font-size: 16px;
	border-bottom-width: 1.5px;
}

.dx-state-focused .dx-label {
	color: #063b6c !important;
}

.dx-label-after {
	border-width: 1.5px 1.5px 1.5px 0px;
}

.dx-label-before {
	border-width: 0px 1.5px 1.5px 1.5px;
}

.dx-editor-outlined.dx-texteditor-with-label.dx-invalid .dx-texteditor-label .dx-label-before,
.dx-editor-outlined.dx-texteditor-with-label.dx-invalid .dx-texteditor-label .dx-label-after,
.dx-editor-outlined.dx-texteditor-with-label.dx-invalid .dx-texteditor-label .dx-label {
	border-color: #e8eaeb !important;
}

.dx-editor-outlined.dx-texteditor-with-label.dx-invalid.dx-state-focused .dx-texteditor-label .dx-label-before,
.dx-editor-outlined.dx-texteditor-with-label.dx-invalid.dx-state-focused .dx-texteditor-label .dx-label-after,
.dx-editor-outlined.dx-texteditor-with-label.dx-invalid.dx-state-focused .dx-texteditor-label .dx-label {
	border-color: #063b6c !important;
}

.dx-invalid.dx-texteditor.dx-editor-outlined.dx-show-invalid-badge .dx-texteditor-input-container::after {
	display: none;
}

.dx-texteditor-input {
	min-height: unset;
}

.dx-placeholder {
	padding-top: 0px; // commented for overall
	font-size: 14px;
	/* Adjust the font size to your preference */
}

.dx-texteditor.dx-editor-outlined input {
	height: 100% !important;
}

a {
	cursor: pointer;
}

.referrals-section {
	margin-bottom: 10px;
}

.referral-form .title-shadow-box {
	width: 100%;
	padding: 8px 20px 12px;
	box-shadow: 0px 3px 6px #00000029;
	border-radius: 13px;
	background-color: #fff;
	//min-height: 70vh;
}

.status-incentive-sec-main {
	display: flex;
	align-items: center;
}

.referral-form .page-title {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.referral-form .page-title .title-fbox .back-icon {
	width: 22px;
	margin-right: 10px;
	cursor: pointer;
}

.referral-form .page-title .title-fbox {
	display: flex;
}

.progress-referrals {
	margin-left: 30px;
}

.referral-form .page-title .progress-referrals span {
	color: #25669f;
	font-size: 16px;
	font-family: Lato-Regular;
	margin-right: 2px;
	// text-decoration: underline;
}

.referral-form .page-title h4 {
	/* padding-block: 12px; */
	margin-bottom: 0 !important;
	color: #024b81;
	font-size: 19px;
	font-family: Lato-Bold;
}

.referral-form .status-sec {
	display: flex;
	padding: 10px;
	align-items: center;
	flex-wrap: wrap;
	padding-left: 0px;
}

.on-hover-referrer {
	position: relative;
}

.on-hover-referrer .referrer-information .info-icon-referrer {
	position: absolute;
	top: 28px;
	right: 24px;
	position: absolute;
	top: 16px;
	right: 24px;
}

.referrer-information .info-icon-referrer .info-icon {
	color: #0275d8;
}

.referrer-information-on-hover {
	display: none;
	position: absolute;
	background: #ffffff;
	border: 1px solid #707070;
	padding: 3px 5px;
	right: 0;
	min-width: 250px;
	border-radius: 5px;
	z-index: 9;
}

.referrer-information-on-hover ul {
	list-style-type: none;
	color: #707070;
	font-size: 15px;
}

.referrer-information-on-hover p {
	color: #303030;
	color: #303030;
	font-weight: bold;
}

.referrer-information:hover .referrer-information-on-hover {
	display: block;
}

.referral-form .page-title p {
	margin-bottom: 0 !important;
	color: #25669f;
	font-size: 15px;
	font-family: Lato-Regular;
}

.referral-form .referral-input {
	padding: 3px 12px !important;
}

.referral-form .referral-select {
	padding: 3px 12px !important;
}

.referral-form .dx-referral-select {
	padding: 0px 5px !important;
}

.referral-form .submit-btn-div {
	margin-top: 16px;
	text-align: center;
}

.submit-btn-div .submit-btn {
	padding: 3px 20px;
	color: #fff;
	background-color: #25669f;
	border-radius: 8px;
	border: none;
}

.referral-form label {
	font-family: Lato-Regular;
	color: #303030;
	font-size: 15px;
}

.dx-input-readonly {
	opacity: 1;
}

//for background & border
.dx-texteditor.dx-state-readonly {
	opacity: 0.7;
	background-color: #f0f0f0;
	border: 1px solid #c6c7c8;
	border-radius: 5px;
}

.referral-form .Incentive-Earned img {
	margin-right: 5px;
}

.referral-form .status-incentive-sec {
	display: flex;
	flex-wrap: wrap;
}

.bubble-display {
	cursor: pointer;
}

.bubble-display .download-bubble {
	color: #8a7153;
}

.referral-form .incentive-sec {
	display: flex;
	/* padding-right: 30px; */
	align-items: center;
	flex-wrap: wrap;
}

.referral-form .text-sp {
	padding-right: 10px;
	font-family: Lato-Medium;
}

.referral-form .status-indicator {
	padding: 5px 10px;
	/* padding: 2px 10px 5px; */
	color: #fff;
	vertical-align: sub;
	/* vertical-align: middle; */
	font-family: Lato-Regular;
	border-radius: 4px;
	border: 1px solid;
	margin-left: 5px;
}

.status-indicator.IN_PROGRESS {
	background-color: $refstatus-in-progress;
	border-color: $refstatus-in-progress;
}

.status-indicator.INCENTIVE_CALCULATION_PENDING {
	background-color: $refstatus-inc-calc-pending;
	border-color: $refstatus-inc-calc-pending;
}

.status-indicator.PENDING {
	background-color: $refstatus-pending;
	border-color: $refstatus-pending;
}

.status-indicator.CLOSED_LOST {
	background-color: $refstatus-closed-lost;
	border-color: $refstatus-closed-lost;
}

.status-indicator.CLOSED_WON {
	background-color: $refstatus-closed-won;
	border-color: $refstatus-closed-won;
}

.status-indicator.Review {
	background-color: $service-desk-status-review;
	border-color: $service-desk-status-review;
}

.status-indicator.Pending_Compliance_Approval {
	background-color: $service-desk-status-pending-compliance-approval;
	border-color: $service-desk-status-pending-compliance-approval;
}

.status-indicator.TRANSFERRED {
	background-color: #9d57e3;
	border-color: #9d57e3;
}

.status-indicator.ON_HOLD {
	background-color: #48cae4;
	border-color: #48cae4;
}

//travel request
.status-indicator.Pending_Approval {
	background-color: #8a7153;
	border-color: #8a7153;
}

.status-indicator.Pending_Travel_Request {
	background-color: #48cae4;
	border-color: #48cae4;
}

.status-indicator.Pending_Travel_Booking {
	background-color: #25669f;
	border-color: #25669f;
}

.status-indicator.Approved {
	background-color: #62d099;
	border-color: #62d099;
}

.status-indicator.Denied {
	background-color: #c73f64;
	border-color: #c73f64;
}

.status-indicator.Canceled {
	background-color: #e3a257;
	border-color: #e3a257;
}

.referral-form .Incentive-Earned {
	background-color: #f3dab2;
	padding: 5px 10px 6px;
	color: #707070;
	vertical-align: sub;
	/* vertical-align: middle; */
	font-family: Lato-Regular;
	border-radius: 4px;
	border: 1px solid #b7b7b7;
	margin-left: 5px;
}

.referral-form .Incentive-Earned img {
	vertical-align: text-bottom !important;
	margin-right: 12px;
}

.upload-file-sec {
	display: inline-block;
	width: 100%;
}

.referral-form .progress-referrals-btn {
	// background-color: transparent;
	// border: none;
	text-decoration: none;
	border: 2px solid #25669f;
	border-radius: 25px;
	padding: 1px 8px 3px;
	font-family: Lato-Regular;
	font-size: 16px;
	background-color: #fff;
	color: #25669f;
}

.referral-form .progress-referrals-btn:hover {
	background-color: #e0f2ff !important;
}

.position-rel {
	position: relative;
}

.error-text {
	display: none;
}

.validation-error .error-text {
	color: #dd2b27 !important;
	font-size: 12px;
	display: block !important;
}

.validation-error .error-symbol {
	top: 10px;
	right: 12px;
	position: absolute;
	color: #dd2b27;
	display: block !important;
}

.validation-error .dx-error-symbol {
	top: 12px;
	right: 12px;
	position: absolute;
	color: #dd2b27;
	display: block !important;
}

.validation-error .select-error-symbol {
	top: 8px;
	right: 38px;
	position: absolute;
	color: #dd2b27;
	display: block !important;
}

.validation-error .dx-select-error-symbol {
	top: 12px;
	right: 4px;
	position: absolute;
	color: #dd2b27;
	display: block !important;
}

.error-symbol,
.select-error-symbol,
.dx-error-symbol,
.dx-select-error-symbol {
	display: none;
}

.position-rel .error-text {
	display: none;
}

.position-rel .error-icon-placement {
	top: 10px;
	right: 36px;
}

.position-rel .error-icon-placement-extended {
	top: 10px;
	right: 60px;
}

/* To add border on focus */

// Wiki manual styles

div.customShadow {
	box-shadow:
		0 2px 6px 0 rgba(0, 0, 0, 0.2),
		0 6px 20px 0 rgba(0, 0, 0, 0.19);
	display: flex;
	flex-direction: row;
	padding: 20px 10px 0px 10px;
	border-radius: 12px;
	background-color: white !important;
}

.mat-mdc-tooltip {
	background-color: transparent !important;
}

/* file capsule */
.upload-file-sec-fbox {
	display: flex;
	flex-wrap: wrap;
	width: 100%;
}

.upload-file-tag-div {
	width: 244px;
}

.upload-file-tag {
	padding: 4px 25px;
	display: inline-block;

	border: 2px dashed #707070;
	border-radius: 10px;
	text-decoration: none;
	color: #707070;
}

.file-bubble-div {
	display: flex;
	flex-wrap: wrap;
}

.bubble-display {
	background-color: #f2dcb5;
	border: none;
	color: #000000;
	padding: 4px 10px;
	font-size: 15px;
	border-radius: 24px;
	display: inline-block;

	margin-bottom: 6px;
	margin-right: 9px;
}

.bubble-display .download-bubble,
.bubble-display .close-bubble {
	color: #8a7153;
}

.category-bubble-class {
	text-align: left;
}

.upload-file-fbox {
	display: flex;
	align-items: center;
}

.upload-file-img {
	height: 32px;
	width: 25px;
}

.upload-file-text {
	text-align: center;
	padding-left: 22px;
	font-size: 14px;
}

.upload-file-blue-text {
	font-family: Lato-Bold;
	color: #2d3081;
}

/* file capsule ed  */

/* Progress Referrals Modal */
.progress-referrals-modal .modal-content {
	background-color: #fff !important;
	border-color: transparent;
}

.progress-referrals-modal .modal-body {
	padding: 0 !important;
}

.progress-referrals-modal .modal-body .close-btn {
	color: #25669f;
	border: none;
	background-color: transparent;
	font-size: 22px;
}

.progress-referrals-modal .align-close-icon {
	text-align: end;
	padding-right: 2px;
}

.progress-referrals-modal .modal-headerr {
	background-color: #fff;
	/* color: #fff; */
	padding: 5px 5px;
	border-radius: 0.3rem 0.3rem 0 0;
}

/* .progress-referrals-modal .modal-head-label {
    vertical-align: sub;
  } */

.progress-referrals-modal .progress-referrals-main {
	padding: 7px 5px 22px;
}

.progress-referrals-main .referral-outcome-text {
	text-align: center;
	align-self: center;
}

.progress-referrals-main h4 {
	font-weight: 400;
	font-size: 20px;
}

.progress-referrals-main .closed-lost-btn:hover,
.progress-referrals-main .incentive-btn:hover,
.progress-referrals-main .closed-won-btn:hover,
.progress-referrals-main .in-progress-btn:hover,
.progress-referrals-main .on-hold-btn:hover {
	border: 1px solid #2e2015;
}

.progress-referrals-main .closed-lost-btn {
	background-color: $refstatus-closed-lost;
	color: $refcommon-white;
	border: none;
	border-radius: 4px;
	width: 90%;
	padding-block: 3px;
	border: 1px solid $refstatus-closed-lost;
}

.progress-referrals-main .incentive-btn {
	background-color: $refstatus-inc-calc-pending;
	color: $refcommon-white;
	border: none;
	border-radius: 4px;
	width: 90%;
	padding-block: 3px;
	border: 1px solid $refstatus-inc-calc-pending;
}

.progress-referrals-main .closed-won-btn {
	background-color: $refstatus-closed-won;
	color: $refcommon-white;
	border: none;
	border-radius: 4px;
	width: 90%;
	padding-block: 3px;
	border: 1px solid $refstatus-closed-won;
}

.progress-referrals-main .in-progress-btn {
	background-color: $refstatus-in-progress;
	color: $refcommon-white;
	border: none;
	border-radius: 4px;
	width: 90%;
	padding-block: 3px;
	border: 1px solid $refstatus-in-progress;
}

.progress-referrals-main .on-hold-btn {
	background-color: #48cae4;
	color: #fff;
	border: none;
	border-radius: 4px;
	width: 90%;
	padding-block: 3px;
	border: 1px solid #48cae4;
}

.progress-referrals-main .btn-bottom-space {
	margin-bottom: 12px;
}

/* Progress Referrals Modal End */

/* Referral Amount Modal */
.referral-amount-modal .modal-content {
	background-color: #fff !important;
	border-color: transparent;
}

.referral-amount-modal .modal-body {
	padding: 0 !important;
}

.referral-amount-modal .modal-body .close-btn {
	color: #25669f;
	border: none;
	background-color: transparent;
	font-size: 22px;
}

.referral-amount-modal .align-close-icon {
	text-align: end;
	padding-right: 2px;
}

.referral-amount-modal .modal-headerr {
	background-color: #fff;
	/* color: #fff; */
	padding: 5px 5px;
	border-radius: 0.3rem 0.3rem 0 0;
}

.referral-amount-modal .referral-amount-main {
	// text-align: center;
	padding: 0 5px 25px;
}

.referral-amount-modal .referral-amount-main .referral-amount-text {
	font-family: Lato-Medium;
	font-size: 20px;
	margin-bottom: 10px !important;
	color: #303030;
}

.referral-amount-modal .referral-amount-main .referral-amount-input {
	box-shadow: 0px 3px 6px #00000029;
	border: 1px solid #707070;
	// margin: auto;
}

.referral-amount-modal .error-text.hasError {
	color: red;
	display: block;
}

.referral-amount-modal .referral-amount-main .referral-amount-input:focus-visible {
	outline: 0;
}

.referral-amount-modal .submit-btn-div {
	margin-top: 24px;
	text-align: center;
}

/* Referral Amount Modal End */

/* Referral filter */

.referral-form .filter-btn-sec {
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
	align-items: center;
}

.referral-form .filter-btn-sec .filter-opt-fbox {
	display: flex;
	flex-wrap: wrap;
}

.referral-form .filter-btn-sec .filter-section {
	display: flex;
	/* align-items: center; */
}

.referral-form .filter-btn-sec .assign-btn {
	align-items: center;
	display: flex;
	justify-content: center;
	border: 1px solid #25669f;
	background: #25669f;
	padding: 5px 23px;

	border-radius: 6px;
	color: #fff;
	font-family: "Lato-Bold";
	font-weight: 600;
}

.referral-form .filter-btn-sec .assign-btn img {
	margin-right: 8px;
}

.referral-form .filter-btn-sec .right-space {
	padding-right: 22px;
}

@media screen and (max-width: 508px) {
	.referral-form .filter-btn-sec .filter-section {
		/* display: flex;
			align-items: center; */
		flex-wrap: wrap;
	}

	.custom-data-grid {
		max-height: calc(100vh - 60vh) !important;
	}
}

@media screen and (max-width: 801px) {
	.referral-form .filter-btn-sec .filter-section {
		margin-block: 8px;
	}

	.custom-data-grid {
		max-height: calc(100vh - 50vh) !important;
	}
}

.referral-form .page-title .title-fbox {
	display: flex;
}

.referral-form .page-title .title-fbox .back-icon {
	width: 22px;
	margin-right: 10px;
}

/* Referral filter end */

.referral-form .page-title .status-incentive-sec {
	display: flex;
	flex-wrap: wrap;
}

.referral-form .page-title .status-incentive-sec .text-sp {
	padding-right: 10px;
	font-family: Lato-Medium;
	color: #024b81;
}

.referral-form .page-title .status-incentive-sec .status-sec {
	display: flex;
	padding-right: 30px;
	align-items: center;
	flex-wrap: wrap;
}

.referral-form .page-title .status-incentive-sec .incentive-sec {
	display: flex;
	/* padding-right: 30px; */
	align-items: center;
	flex-wrap: wrap;
}

//overall checkbox border changed here

.dx-checkbox-icon {
	border: 1px solid #25669f !important;
}

app-branch-admin-dashboard .filter-opt-fbox .dx-checkbox-icon {
	margin-bottom: 4px;
}

app-marketing-dashboard .filter-opt-fbox .dx-checkbox-icon {
	margin-bottom: 4px;
}

app-facilities-dashboard .filter-opt-fbox .dx-checkbox-icon {
	margin-bottom: 4px;
}

app-view-tickets .filter-opt-fbox .dx-checkbox-icon {
	margin-bottom: 4px;
}

app-relationship-analysis .filter-opt-fbox .dx-checkbox-icon {
	margin-top: 3px;
}

app-travel-request-dashboard .filter-opt-fbox .dx-checkbox-icon {
	margin-bottom: 4px;
}

app-view-user-referral,
app-employee-referral-dashboard,
app-wealth-referral-dashboard,
app-treasury-referral-dashboard,
app-mortgage-referral-dashboard,
app-merchant-referral-dashboard,
app-loan-referral-dashboard,
app-international-private-client-referral-dashboard,
app-insurance-referral-dashboard,
app-fx-referral-dashboard,
app-employee-referral-dashboard,
app-domestic-private-client-referral-dashboard,
app-deposit-account-referral-dashboard,
app-credit-card-referral-dashboard {
	// for checkbox alignment with label
	.approve-content-wrapper-checkbox .dx-checkbox-container {
		margin-top: -5px !important;
	}
}

app-fx-referral,
app-deposit-account-referral,
app-credit-card-referral,
app-domestic-private-client-referral,
app-international-private-client-referral,
app-loan-referral,
app-deposit-account-edit,
app-domestic-private-client-edit,
app-fx-edit,
app-international-private-client-edit,
app-loan-edit,
app-credit-card-edit {
	.borderUnSET {
		border: unset !important;
		background-color: whitesmoke !important;
		width: 36px !important;
		margin-left: 5px;

		& .dx-dropdowneditor-input-wrapper .hideInput {
			display: none !important;
		}

		& .dx-dropdowneditor-input-wrapper .dx-texteditor-input-container {
			display: none !important;
		}

		& .dx-widget {
			margin-left: 5px;
		}
	}
}

app-configure-incentive-dashboard {
	.approve-content-wrapper-checkbox .dx-checkbox-container {
		margin-top: -5px !important;
	}
}

.referral-form .chat-sec {
	//background-color: #eaeaea;
	border-radius: 5px;
	margin-top: 16px;
	/* padding: 8px 10px; */
	background-color: #f5f5f5;
	border: 1px solid #b7b7b7;
	box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1607843137);
}

.referral-form .chat-sec .chat-date {
	text-align: center;
}

.referral-form .chat-sec .chat-date .date-box {
	background-color: #535353;
	padding: 3px 9px;
	color: #e5e5e5;
	border-radius: 6px;
	font-size: 13px;
	font-family: Lato-Medium;
}

.referral-form .chat-sec .message-spacing {
	padding: 8px 10px;
	height: 241px;
	overflow-y: scroll;
}

.referral-form .chat-sec .receiver-chat-sec {
	text-align: -webkit-right;
	display: flex;
	justify-content: flex-end;
}

.referral-form .chat-sec .sender-chat-sec,
.referral-form .chat-sec .receiver-chat-sec {
	margin-top: 5px;
}

.referral-form .chat-sec .sender-chat {
	background-color: #fff;
	padding: 4px 8px;
	width: fit-content;
	border-radius: 10px;
	max-width: 90%;
	font-size: 16px;
	font-family: Lato-Regular;
	box-shadow: 0px 3px 6px #00000029;
}

.referral-form .chat-sec .chat-message {
	word-break: break-word;
	text-align: left;
}

.referral-form .chat-sec .message-time {
	color: #707070;
	font-size: 10px;
	font-family: Lato-Regular;
	text-align: end;
}

.referral-form .chat-sec .receiver-chat {
	background-color: #e7ffdb;
	padding: 4px 8px;
	width: fit-content;
	border-radius: 10px;

	max-width: 90%;
	font-size: 16px;
	font-family: Lato-Regular;
	box-shadow: 0px 3px 6px #00000029;
}

.referral-form .chat-sec .sender-name,
.referral-form .chat-sec .receiver-name {
	color: #8a7153;
	font-size: 10px;
	font-family: Lato-Bold;
}

.referral-form .chat-sec .receiver-name {
	color: #8a7153;
	font-size: 10px;
	font-family: Lato-Bold;
	text-align: left;
}

app-merchant-edit,
app-wealth-edit {
	#credit-card-accept-text {
		color: $save-green;
		margin-top: 25px;
	}

	#credit-card-not-accept-text {
		color: $active-color;
		margin-top: 25px;
	}
}

app-merchant,
app-wealth-edit,
app-employee-photos {
	.pill-btn-yes {
		font-size: 15px;
		border-top-left-radius: 8px;
		border-bottom-left-radius: 8px;
		transition:
			border-color 0.15s ease-in-out,
			box-shadow 0.15s ease-in-out !important;
	}

	.pill-btn-no {
		font-size: 15px;
		border-top-right-radius: 8px;
		border-bottom-right-radius: 8px;
		transition:
			border-color 0.15s ease-in-out,
			box-shadow 0.15s ease-in-out !important;
	}

	#inline-container-credit-card-yn {
		display: unset;
		overflow: hidden;
		align-content: center;
		flex-wrap: wrap;
	}

	#inline-container-employee-photos {
		display: unset;
		overflow: hidden;
		align-content: center;
		flex-wrap: wrap;
	}

	.dx-button-mode-contained.dx-state-hover {
		border-color: rgba(6, 59, 108, 0.4) !important;
	}

	.dx-state-disabled.dx-button {
		background-color: #063b6c !important;
		color: white;
	}

	.display-image .ngx-ic-cropper {
		display: none !important;
	}
}

.form-bottom-padding {
	padding-bottom: 50px !important;
}

.form-responsive-height {
	height: 74vh !important;
	overflow-y: scroll;
	padding-bottom: 45px !important;
}

.messageBar {
	width: 99%;
	/* margin-right: 15px; */
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
}

#messageInput {
	width: 99%;
	height: 35px;
	padding: 6px 53px 6px 10px;
	font-size: 13px;
	background: #ffffff 0% 0% no-repeat padding-box;
	/* box-shadow: 0px 3px 6px #00000029; */
	border: none;
	border-radius: 23px;
	font-family: Lato-Regular;
}

#messageInput:focus-visible {
	outline: 0;
}

#messageSubmit {
	width: 2.5rem;
	height: 1.8rem;
	margin-left: -3rem;
	background: #ffffff;
	border: none;
	outline: none;
	border-left: 1px solid #024b81;
}

.chat-sec .send-message-sec {
	background-color: #98c8e0;
	padding-block: 5px;
	border-bottom-left-radius: 5px;
	border-bottom-right-radius: 5px;
}

.disabled-send-message {
	background-color: #98c8e0;
	padding: 4px 5px 0;
	border-bottom-left-radius: 5px;
	border-bottom-right-radius: 5px;
}

.messageChat #messageInput {
	background-color: #e9ecef !important;
	opacity: 1;
}

.messageChat #messageInput {
	background-color: #e9ecef !important;
	opacity: 1;
}

.messageChat #messageSubmit {
	background-color: #e9ecef !important;
	opacity: 1;
	position: absolute;
	right: 9px;
	top: 3px;
}

.messageChat {
	position: relative;
}

.mandatoryField {
	color: red;
	font-size: 15px;
}

.ifKnown-Field {
	margin-left: 2px;
}

@media screen and (min-width: 1600px) {
	.form-responsive-height {
		height: 82vh !important;
	}

	.custom-data-grid {
		max-height: calc(100vh - 20vh);
	}
}

@media screen and (max-width: 1599px) {
	.form-responsive-height {
		height: 76vh !important;
	}
}

@media screen and (max-width: 1370px) {
	.form-responsive-height {
		height: 71vh !important;
	}
}

@media screen and (max-width: 1050px) {
	.form-responsive-height {
		height: 70vh !important;
	}
}

// @media screen and (max-width: 950px) {
// 	.form-responsive-height {
// 		height: 71vh !important;
// 	}
// }

@media screen and (max-width: 768px) {
	.form-responsive-height {
		height: 66vh !important;
	}
}

@media screen and (max-width: 400px) {
	.form-responsive-height {
		height: 70vh !important;
	}
}

.referral-form .section-box {
	position: relative;
	background-color: #f5f5f5;
	border: 1px solid #b7b7b7;
	box-shadow: 0px 3px 6px #00000029;
	border-radius: 4px;
	padding: 0 16px 16px;
	margin-top: 20px;
}

.referral-form .section-header {
	position: absolute;
	background-color: #ffffff;
	border: 1px solid #707070;
	border-radius: 6px;
	padding: 0px 8px;
	top: -14px;
	left: 30px;
}

.referral-form .section-header label {
	font-size: 15px;
	font-family: Lato-Bold;
}

.referral-amount-main h4 {
	font-size: 28px;
	font-weight: 400;
	text-align: center;
	color: #25669f;
}

app-relationship-analysis {
	.b-hover {
		background: rgba($color: #03a9f4, $alpha: 0.02) !important;
	}

	.b-gridbase:not(.b-moving-splitter),
	.b-grid-subgrid:not(.b-timeaxissubgrid),
	.b-grid-row:not(.b-group-row).b-hover .b-grid-cell {
		background-color: rgba($color: #03a9f4, $alpha: 0.02) !important;
	}

	.b-checkbox {
		--checkbox-checked-background-color: #1592e6 !important;
		--checkbox-checked-check-color: white !important;
	}
}

.credit-card-checkbox {
	display: flex;
}

.credit-card-checkbox h4 {
	padding-right: 15px;
	padding-top: 8px;
	font-size: 16px;
}

.dx-checkbox.dx-state-readonly .dx-checkbox-icon {
	opacity: 0.7;
	background-color: #f0f0f0;
	border: 1px solid #c6c7c8 !important;
}

.transfer-referral-modal .error-text.hasError {
	color: red;

	display: block;
}

.referral-tab .pending-status {
	background-color: $refstatus-pending;
	padding: 3px 10px;
	color: $refcommon-white;
	font-family: Lato-Regular;
	border-radius: 4px;
}

.referral-tab .incentive-status {
	background-color: $refstatus-inc-calc-pending;
	padding: 3px 10px;
	color: $refcommon-white;
	font-family: Lato-Regular;
	border-radius: 4px;
	/* width: 90%; */
}

.referral-tab .closed-won-status {
	background-color: $refstatus-closed-won;
	padding: 3px 10px;
	color: $refcommon-white;
	font-family: Lato-Regular;
	border-radius: 4px;
	/* width: 90%; */
}

.referral-tab .closed-lost-status {
	background-color: $refstatus-closed-lost;
	padding: 3px 10px;
	color: $refcommon-white;
	font-family: Lato-Regular;
	border-radius: 4px;
	/* width: 90%; */
}

.referral-tab .in-progress-status {
	background-color: $refstatus-in-progress;
	padding: 3px 10px;
	color: $refcommon-white;
	font-family: Lato-Regular;
	border-radius: 4px;
	/* width: 90%; */
}

.btn-performance-transfer {
	background-color: varperformance.$trb-performance-transfer !important;
	color: var.$white !important;
}

.btn-performance-transfer .transfer-icon {
	font-size: 30px !important;
	height: 29px !important;
	width: 30px !important;
}

//loader
.appLoader .ngx-spinner-overlay {
	top: 0% !important;
	left: 7% !important;
}

// for user-history and view-to-do-list
.dashboard-appLoader {
	position: relative;
	left: 30%;
	top: 60%;
}

// .dashboardLoader .loading-text {
// 	margin-top: 40px;
// }

.disablePointerReferralForm {
	pointer-events: none;
}

app-view-mlo-listing {
	.app-container {
		margin: 0 auto;
		width: 100%;
		text-align: center;
		height: 100%;
		padding: 0 !important;
		display: flex;
		flex-direction: column;
	}

	.bank {
		font-weight: 525;
		font-size: 26px;
		text-transform: uppercase;
		color: rgb(34, 97, 139);
		font-family: Melior Regular;
	}

	#data-grid-container {
		flex-grow: 1;
		padding-inline: 20px;
		overflow-y: hidden;
	}

	.gold-star {
		height: 75px;
	}

	.bank-cap {
		font-size: 36px;
	}

	.bank-nmls {
		font-weight: 500;
		font-size: 15px;
	}

	.b-grid-header-text {
		color: #000000;
		font-weight: 900;
	}

	.b-grid-header {
		border: 1px solid black;
	}

	.b-grid-cell {
		border: 1px solid black;
	}

	.grid-button {
		box-shadow: 1px 1px 3px #888888;
		padding-inline: 6px;
		border-radius: 3px !important;
		color: black;
		font-weight: 505;
		margin-right: 20px;
	}

	.b-fa-file-excel {
		font-size: 30px !important;
		color: $excel-green;
	}
}

app-base-regional-report-dashboard {
	.container {
		margin: 0 auto;
		width: 100%;
		padding: 10px;
		text-align: center;
		height: 100%;
		display: flex;
		flex-flow: column;
	}

	.title {
		font-weight: 500;
	}

	#grid-div {
		flex: 1 1 auto;
		box-shadow: 4px 4px 8px #888888;
		border: 1px solid black;
	}

	.b-grid-header {
		color: white !important;
		background-color: rgb(2, 75, 129) !important;
		text-transform: none !important;
	}

	.b-grid-header:hover {
		background-color: red;
	}

	.b-hover {
		background-color: rgba(3, 122, 212, 0.4) !important;
	}

	.grid-button {
		box-shadow: 1px 1px 3px #888888;
		padding-inline: 6px;
		border-radius: 3px !important;
		color: black;
		font-weight: 505;
		margin-right: 20px;
	}

	.b-fa-file-excel {
		font-size: 30px !important;
		color: $excel-green;
	}
}

app-user-group-create {
	.dx-datagrid-header-panel .dx-toolbar {
		margin-bottom: 10px;
		margin-top: 10px;
	}
}

app-menu-group-create {
	.dx-toolbar .dx-toolbar-items-container {
		height: 36px;
		overflow: visible;
		margin-top: 10px;
	}
}

app-stand-alone-data-grid {
	dx-row.dx-data-row.dx-column-lines.dx-state-hover td {
		background: #e1ecf5 !important;
		border-left-color: #e1ecf5;
		border-right-color: #e1ecf5;
	}

	.grid-edit-hover .dx-row.dx-data-row.dx-row-lines.dx-state-hover {
		cursor: pointer;
	}

	.dx-datagrid .dx-header-filter {
		color: #f2dcb5 !important;
	}

	.dx-datagrid .dx-header-filter-empty {
		color: #fff !important;
	}
}

app-generate-qr-code,
app-campaign-details-modal,
app-stand-alone-data-grid {
	// Toggle

	.toggle-col {
		align-self: center;
		padding-left: 0;
	}

	.toggle-col .toggle {
		position: relative;
		display: block;
		width: 75px;
		height: 40px;
		padding: 3px;
		margin: auto;
		border-radius: 50px;
		cursor: pointer;
	}

	.toggle-col .toggle-input {
		position: absolute;
		top: 0;
		left: 0;
		opacity: 0;
	}

	.toggle-col .toggle-label {
		position: relative;
		display: block;
		height: 30px;
		font-size: 14px;
		background: #808080;
		border-radius: inherit;
		box-shadow:
			inset 0 1px 2px rgba(0, 0, 0, 0.12),
			inset 0 0 3px rgba(0, 0, 0, 0.15);
	}

	.toggle-col .toggle-label:before,
	.toggle-col .toggle-label:after {
		position: absolute;
		top: 50%;
		color: black;
		margin-top: -0.5em;
		line-height: 1;
	}

	.toggle-col .toggle-label:before {
		content: attr(data-off);
		right: 12px;
		color: #fff;
		text-shadow: 0 1px rgba(255, 255, 255, 0.5);
	}

	.toggle-col .toggle-label:after {
		content: attr(data-on);
		left: 12px;
		color: #fff;
		text-shadow: 0 1px rgba(0, 0, 0, 0.2);
		opacity: 0;
	}

	.toggle-col .toggle-input:checked ~ .toggle-label {
		/* background: rgba(0, 128,0); */
		background: #86c142;
	}

	.toggle-col .toggle-input:checked ~ .toggle-label:before {
		opacity: 0;
	}

	.toggle-col .toggle-input:checked ~ .toggle-label:after {
		opacity: 1;
	}

	.toggle-col .toggle-handle {
		position: absolute;
		top: 4px;
		left: 4px;
		width: 29px;
		height: 28px;
		background: linear-gradient(to bottom, #ffffff 40%, #f0f0f0);
		border-radius: 50%;
	}

	.toggle-col .toggle-handle:before {
		position: absolute;
		top: 50%;
		left: 50%;
		margin: -6px 0 0 -6px;
		width: 16px;
		height: 16px;
	}

	.toggle-col .toggle-input:checked ~ .toggle-handle {
		left: 42px;
		box-shadow: -1px 1px 5px rgba(0, 0, 0, 0.2);
	}

	/* Transition*/
	.toggle-col .toggle-label,
	.toggle-col .toggle-handle {
		transition: All 0.3s ease;
		-webkit-transition: All 0.3s ease;
		-moz-transition: All 0.3s ease;
		-o-transition: All 0.3s ease;
	}
}

app-role-create,
app-menu-group-create,
app-workflow,
app-task,
app-topic,
app-user-create {
	.ngx-spinner-overlay {
		position: fixed;
		width: 100%;
		height: 100%;
	}
}

app-favorite {
	.ngx-spinner-overlay {
		position: fixed;
		top: -20% !important;
		left: 0;
		width: 100%;
		height: 100%;
	}
}

app-overdraft-rules {
	.span-text p {
		margin-bottom: 0px !important;
	}
}

app-view-mlo-listing {
	.app-container {
		margin: 0 auto;
		width: 100%;
		padding: 10px;
		text-align: center;
		height: 100%;
		display: flex;
		flex-flow: column;
	}

	#grid-div {
		flex: 1 1 auto;
		box-shadow: 8px 8px 18px #888888;
		margin-inline: 30px;
		border: 1px solid black;
	}

	.bank {
		font-weight: 525;
		font-size: 26px;
		text-transform: uppercase;
		color: rgb(34, 97, 139);
		font-family: Melior Regular;
	}

	.gold-star {
		height: 50px;
	}

	.bank-cap {
		font-size: 36px;
	}

	.bank-nmls {
		font-weight: 500;
		font-size: 15px;
	}

	.b-grid-header-text {
		color: #000000;
		font-weight: 900;
	}

	.b-grid-header {
		border: 1px solid black;
	}

	.b-grid-cell {
		border: 1px solid black;
	}

	.grid-button {
		box-shadow: 1px 1px 3px #888888;
		padding-inline: 6px;
		border-radius: 3px !important;
		color: black;
		font-weight: 505;
		margin-right: 20px;
	}

	.b-fa-file-excel {
		font-size: 30px !important;
		color: $excel-green;
	}
}

app-base-regional-report-dashboard {
	.container {
		margin: 0 auto;
		width: 100%;
		padding: 10px;
		text-align: center;
		height: 100%;
		display: flex;
		flex-flow: column;
	}

	#grid-div {
		flex: 1 1 auto;
		box-shadow: 8px 8px 18px #888888;
		margin-inline: 30px;
		border: 1px solid black;
	}

	.custom-style .dx-texteditor.dx-editor-outlined {
		margin-top: 0;
	}
}

.viewFeedbackGrid .title-shadow-box {
	width: 100%;
	padding: 8px 20px 12px;
	box-shadow: 0px 3px 6px #00000029;
	border-radius: 13px;
	background-color: #fff;
	min-height: 70vh;
}

.clear-filter-btn {
	border: 1px solid #024b81;
	background: #f6f9ff;
	padding: 5px 19px;
	border-radius: 6px;
	color: #024b81;
	font-family: "Lato-Bold";
	font-weight: 600;
	margin-left: 11px;
}

.clear-filter-btn img {
	margin-right: 8px;
}

app-add-edit-page-content {
	.boxShadow .dx-texteditor-container {
		border: 1px solid #817b7b !important;
	}

	.dx-texteditor.dx-state-readonly {
		border-style: none;
	}

	.mat-icon {
		width: auto;
		height: auto;
	}
}

app-wiki-sidebar {
	.dx-item.dx-treeview-item {
		background-color: transparent !important;
		color: #024b81 !important;
		padding-bottom: 0;
	}

	.wiki-sidebar-list {
		display: grid;
	}

	.wiki-sidebar-list ul li {
		margin-top: 0px !important;
	}

	.dx-texteditor.dx-editor-outlined {
		margin-bottom: 10px;
	}

	.dx-treeview-scrollable {
		overflow: unset !important;
	}
}

app-edit-page-content {
	.main-module .dx-texteditor-container {
		border: 1px solid #817b7b !important;
	}

	.main-module .dx-placeholder {
		padding-top: unset;
	}

	.dx-item.dx-treeview-item {
		background-color: transparent !important;
		color: #024b81 !important;
		padding-bottom: 0;
	}

	.main-module label {
		color: #484545 !important;
	}

	.title-shadow-box .dx-empty-message {
		text-align: center;
		font-weight: 500;
		font-size: 18px;
		margin: 10px auto;
		font-family: "Lato", sans-serif !important;
	}

	.parent-loop-box .dx-treeview-node-container ul {
		margin-left: 24px;
		padding: 0px 25px 6px 10px;
		border: 1px solid #b7b7b7;
		background-color: #f9f9f9;
		border-radius: 5px;
		margin-top: 5px;
	}

	.parent-loop-box .dx-treeview-node-container ul ul {
		margin-left: 24px;
		padding: 0px 25px 6px 10px;
		border: 1px solid #b7b7b7;
		background-color: #e0e0e0;
		border-radius: 5px;
		margin-top: 5px;
	}

	.parent-loop-box .dx-treeview-node-container ul ul ul {
		margin-left: 0px;
		padding: 0px 6px 6px 10px;
		border: 1px solid #b7b7b7;
		background-color: #d1cfcf;
		border-radius: 5px;
		margin-top: 5px;
	}

	.parent-loop-box .dx-item.dx-treeview-item {
		padding-top: 0;
		padding-bottom: 0;
	}

	@media screen and (max-width: 1380px) {
		.title-list-sec .button-icons-div {
			padding-left: 10px;
		}

		.parent-loop-box .dx-treeview-node-container ul {
			margin-left: 10px !important;
		}

		.parent-loop-box .dx-treeview-node-container ul ul {
			padding: 0px 18px 6px 6px !important;
			margin-left: 8px !important;
		}

		.parent-loop-box .dx-treeview-node-container ul ul ul {
			padding: 0px 10px 6px 6px !important;
			margin-left: 5px !important;
		}

		.title-list-sec .icon-margin-right-25 {
			margin-right: 10px;
		}
	}

	@media screen and (max-width: 1250px) {
		.parent-loop-box .dx-treeview-node-container ul {
			margin-left: 6px !important;
		}

		.title-list-sec .button-icons-div {
			padding-left: 15px;
		}
	}

	@media screen and (max-width: 1024px) {
		.parent-loop-box .dx-treeview-node-container ul ul {
			padding: 0px 8px 6px 10px !important;
		}

		.parent-loop-box .dx-treeview-node-container ul ul ul {
			margin-left: 15px !important;
		}

		.title-list-sec .button-icons-div {
			padding-left: 12px;
		}
	}

	@media screen and (max-width: 991px) {
		.title-list-sec .parent-module {
			position: relative;
			display: block;
		}

		.title-list-sec .parent-module .form-floating {
			//margin-left: 30px;
			width: 95%;
			position: relative;
		}

		.title-list-sec .sub-module-level-one .form-floating {
			//margin-left: 30px;
			width: 95%;
			position: relative;
		}

		.title-list-sec .sub-module-level-two .form-floating {
			//margin-left: 30px;
			width: 95%;
			position: relative;
		}

		.title-list-sec .sub-module-level-three .form-floating {
			//margin-left: 30px;
			width: 95%;
			position: relative;
		}

		.title-list-sec .inline-img {
			position: absolute;
		}

		.title-list-sec .button-icons-div {
			/* width: 30%; */
			padding-right: 12px;
			margin: 5px 0;
			align-self: center;
			text-align: right;
		}

		.title-list-sec .sub-module-level-one {
			display: block;
		}

		.title-list-sec .sub-module-level-two {
			display: block;
		}

		.title-list-sec .sub-module-level-three {
			display: block;
		}

		.title-list-sec .col-margin-top {
			margin-top: 10px;
		}

		.title-list-sec .align-item-end {
			text-align: start;
		}

		.search-page-sec .filter-col-div {
			text-align: left;
		}

		.parent-loop-box .dx-treeview-node-container ul {
			padding: 0px 4px 6px 4px !important;
		}

		.parent-loop-box .dx-treeview-node-container ul ul {
			padding: 0px 4px 6px 4px !important;
		}

		.parent-loop-box .dx-treeview-node-container ul ul ul {
			padding: 0px 4px 6px 4px !important;
		}
	}
}

app-wiki {
	.wiki-layout .layout-container-withMargin {
		display: unset;
	}
}

app-campaign-management-dashboard {
	.campaign-dashboard-sec
		.dx-datagrid-table
		.dx-data-row.dx-state-hover:not(.dx-selection):not(.dx-row-inserted):not(.dx-row-removed):not(.dx-edit-row)
		> td:not(.dx-focused) {
		cursor: pointer;
		background-color: #c6e1f3;
	}
}

app-view-to-do-list {
	.todo-data-grid
		.dx-datagrid-table
		.dx-data-row.dx-state-hover:not(.dx-selection):not(.dx-row-inserted):not(.dx-row-removed):not(.dx-edit-row):not(
			.dx-row-focused
		)
		> td:not(.dx-focused) {
		background-color: #c6e1f3;
		color: #333;
	}

	.approve-content-wrapper-checkbox .dx-checkbox-container {
		margin-top: -4px !important;
	}

	.to-do-layout .layout-container-withMargin {
		margin-left: 0rem !important;
	}

	.to-do-event-content {
		white-space: normal;
		min-width: 180px;
	}

	//To-Do State

	.to-do-state-text {
		text-align: center;
		padding: 3px 10px;
		border-radius: 4px;
		width: max-content;
		margin: 0 auto;
		color: $todo-common-black;
	}

	.state-past-due {
		background-color: $todoState-past-due;
	}

	.state-due-today {
		background-color: $todoState-due-today;
	}

	.state-upcoming {
		background-color: $todoState-upcoming;
	}

	.state-snoozed {
		background-color: $todoState-snoozed;
	}

	.state-completed {
		background-color: $todoState-completed;
	}

	.state-cancelled {
		background-color: $todoState-cancelled;
		color: white !important;
	}

	//Status
	.status-cancelled {
		padding: 3px 10px;
		color: $todoStatus-cancelled;
		font-family: Lato-Regular;
	}

	.status-scheduled {
		padding: 3px 10px;
		color: $todoStatus-scheduled;
		font-family: Lato-Regular;
	}

	.status-completed {
		padding: 3px 10px;
		color: $todoStatus-completed;
		font-family: Lato-Regular;
	}

	.snooze-icon {
		font-size: 16px;
		color: #d4b796;
		vertical-align: middle;
	}

	.edit-event-icon {
		color: $todoStatus-scheduled;
		cursor: pointer;
		margin-left: 5px;
		font-size: 17px;
		font-family: Lato-Bold;
	}

	.complete-event-icon {
		color: $todoStatus-completed;
		cursor: pointer;
		margin-left: 5px;
		font-size: 17px;
		font-family: Lato-Bold;
	}

	.cancel-event-icon {
		color: $todo-past-event-border-color;
		cursor: pointer;
		margin-left: 5px;
		font-size: 17px;
		font-family: Lato-Bold;
	}

	.snooze-event-icon {
		color: $todoState-snoozed;
		cursor: pointer;
		margin-left: 5px;
		font-size: 17px;
		font-family: Lato-Bold;
	}

	.redirect-event-icon {
		color: $todoState-redirect;
		cursor: pointer;
		margin-left: 5px;
		font-size: 17px;
		font-family: Lato-Bold;
	}

	.action-icons-container {
		display: flex;
		align-items: center;
	}

	.icon-bold {
		font-weight: bold;
	}

	.plus-icon {
		margin-left: 5px;
	}
}

.dx-menu-horizontal {
	height: 50% !important;
}

.dashboard-appLoader .ng-star-inserted {
	margin-left: 120px;
}

.dx-submenu {
	min-width: 180px;
}

.dx-menu-item-content {
	padding: 0px 10px 7px 0px !important;

	// padding-left: 0px !important;
}

app-merchant-edit,
app-insurance-edit,
app-treasury-edit,
app-wealth-edit {
	/* updating the button text */

	.dx-fileuploader-button .dx-button-text {
		color: #ffff;

		cursor: pointer;
	}

	.dx-fileuploader-button.dx-widget {
		/* referring to material button style */

		box-shadow:
			0px 3px 1px -2px rgb(0 0 0 / 20%),
			0px 2px 2px 0px rgb(0 0 0 / 14%),
			0px 1px 5px 0px rgb(0 0 0 / 12%);

		border-radius: 4px;

		background-color: #063b6c;
	}

	.dx-fileuploader-wrapper {
		padding: 0px;
	}

	.dx-fileuploader-input-wrapper {
		padding: 0px 0;

		border: 3px dashed transparent;
	}

	.dx-button-has-text .dx-button-content {
		overflow: hidden;

		text-overflow: ellipsis;

		white-space: nowrap;

		text-align: center;
	}

	/* hide addtional label with the button */

	.dx-fileuploader-input-container {
		display: none;
	}

	.mat-mdc-card {
		transition: box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1);

		display: block;

		position: relative;

		padding: unset !important;

		border-radius: 4px !important;

		padding-left: 14px !important;
	}
}

app-campaign-details-modal {
	.edit-campaign-modal .dx-switch-container {
		height: 30px;
		padding: 0;
		border-color: #fff;
		border: none;
	}

	//Inactive
	.edit-campaign-modal .dx-switch-handle {
		flex-basis: unset;
		width: 28px;
		height: 30px;
		margin-top: unset;
		z-index: 2;
	}

	//entire Inactive button
	.edit-campaign-modal .dx-switch-off {
		padding-left: 32px;
		font-size: 13px;
		line-height: 26px;
		color: #fff;
		background-color: #808080 !important;
		padding-right: 5px;
		transform: translateX(-33%);
		border-radius: 20px;
	}

	//Active button
	.edit-campaign-modal .dx-switch-on {
		padding-right: 30px;
		font-size: 13px;
		line-height: 26px;
		color: #fff;
		background-color: #86c142 !important;
		border-radius: 20px;
	}

	//this is for Active state - round btn
	.edit-campaign-modal .dx-switch-on-value .dx-switch-handle::before {
		background-color: #fff;
		border-radius: 20px;
		width: 27px;
		height: 26px;
		margin: 2px;
		margin-left: -1px;
	}

	//this is for inactive state - round btn
	.edit-campaign-modal .dx-switch-handle::before {
		background-color: #fff;
		border-radius: 20px;
		margin: 2px;
		margin-left: 2px;
		height: 26px;
		width: 27px;
	}

	.edit-campaign-modal .dx-switch .dx-state-focused .dx-switch-container {
		border-color: #fff;
		border: none;
	}

	//this is for focus color on click
	.edit-campaign-modal .dx-switch .dx-state-focused {
		background-color: #fff;
	}

	//this is for focus before click
	.edit-campaign-modal .dx-switch.dx-state-focused .dx-switch-handle::before {
		background-color: #fff;
	}

	//this appears on hover effect
	.edit-campaign-modal .dx-switch.dx-state-hover .dx-switch-handle::before {
		background-color: #fff;
	}

	.edit-campaign-modal .dx-switch.dx-state-active .dx-switch-container {
		background-color: #fff !important;
	}
}

app-lookup {
	.lookup-layout .layout-container-withMargin {
		display: unset;
		// margin-left: 1rem !important;
		// margin-right: 1rem !important;
	}

	app-lookup-sidebar {
		.dx-item.dx-treeview-item {
			background-color: transparent !important;
			color: #024b81 !important;
			padding-bottom: 0;
		}

		.dx-treeview-scrollable {
			overflow: unset !important;
		}

		.lookup-sidebar-list {
			display: grid;
		}

		.lookup-sidebar-list ul li {
			margin-top: 0px !important;
		}
	}
}

//quill common styles

.quill-common-popup .dx-overlay-content {
	width: auto !important;
	height: auto !important;
}

.quill-common-popup .tabpanel-item {
	padding: 20px;
}

.quill-common-popup .dx-popup-title .dx-closebutton .dx-icon {
	color: #fff;
}

.quill-common-popup .dx-tabpanel > .dx-tabpanel-tabs .dx-tab.dx-tab-selected {
	background-color: #f3dab2;
}

.quill-common-popup .dx-tabpanel > .dx-tabpanel-tabs .dx-tab.dx-tab-selected .dx-tab-content {
	color: #8a7153;
	font-weight: 600;
}

.quill-common-popup .dx-button-has-text .dx-button-content:hover {
	background-color: #25669f;
	color: #fff;
	opacity: 0.8;
}

.quill-common-popup .dx-popup-title.dx-toolbar {
	background-color: #25669f;
	color: #fff;
}

//quill image popup styles

.quill-image-popup .dx-button-content {
	background-color: #25669f;
	color: #fff;
}

mat-form-field .quill-image-popup .mat-mdc-text-field-wrapper {
	border: unset;
}

//quill video popup

.quill-video-popup .dx-fileuploader-input-wrapper .dx-button-content {
	background-color: #25669f;
	color: #fff;
}

.quill-video-popup .dx-popup-content-scrollable {
	height: auto !important;
}

.quill-video-popup .dx-fileuploader-show-file-list .dx-fileuploader-files-container {
	padding-top: 0px;
}

.quill-video-popup .video-cancel-button {
	border: 1px solid #024b81;
	background-color: white;
	color: #024b81;
	border-radius: 6px !important;
	font-weight: 600 !important;
	font-family: "Lato" !important;
	padding: 4px 10px;
}

.quill-video-popup .video-save-button {
	background-color: #024b81;
	color: #fff;
	border-radius: 6px !important;
	font-weight: 600 !important;
	font-family: "Lato" !important;
	padding: 4px 10px;
	border-color: #024b81;
}

.quill-video-popup .cancel-button .dx-button-content {
	border: 1px solid #024b81;
	background-color: white;
	color: #024b81;
}

.quill-video-popup .add-button .dx-button-content {
	background-color: #024b81;
	color: #fff;
}

.quill-video-popup .dx-button-mode-contained .dx-icon {
	color: #024b81;
}

.quill-video-popup .dx-button-content {
	border-radius: 6px !important;
	font-weight: 600 !important;
	font-family: "Lato" !important;
}

.quill-video-popup .dx-button {
	border-style: none;
}

.progress-referral-popup .dx-popup-content {
	padding: 1px 8px !important;
}

.progress-referral-popup .dx-show-clear-button .dx-icon-clear {
	right: 14px;
}

.service-desk .status-indicator {
	padding: 5px 10px;
	/* padding: 2px 10px 5px; */
	color: #fff;
	vertical-align: sub;
	/* vertical-align: middle; */
	font-family: Lato-Regular;
	border-radius: 4px;
	//border: 1px solid;
	margin-left: 5px;

	&.Pending {
		background-color: $refstatus-pending;
		border-color: $refstatus-pending;
	}

	&.InProgress {
		background-color: $refstatus-in-progress;
		border-color: $refstatus-in-progress;
	}

	&.Completed {
		background-color: $refstatus-closed-won;
		border-color: $refstatus-closed-won;
	}

	&.Declined {
		background-color: $refstatus-closed-lost;
		border-color: $refstatus-closed-lost;
	}
}

app-stand-alone-data-grid {
	.pending-review {
		background-color: #25669f;
		padding: 3px 10px;
		color: #fff;
		font-family: Lato-Regular;
		border-radius: 4px;
		width: max-content;
		margin: 0 auto;
	}

	.approved {
		background-color: #62d099;
		padding: 3px 10px;
		color: #fff;
		font-family: Lato-Regular;
		border-radius: 4px;
		width: max-content;
		margin: 0 auto;
	}

	.denied {
		background-color: #c73f64;
		padding: 3px 10px;
		color: #fff;
		font-family: Lato-Regular;
		border-radius: 4px;
		width: max-content;
		margin: 0 auto;
	}

	.in-process {
		background-color: #8a7153;
		padding: 3px 10px;
		color: #fff;
		font-family: Lato-Regular;
		border-radius: 4px;
		width: max-content;
		margin: 0 auto;
	}

	.withdrawn {
		background-color: #fb9f1a;
		padding: 3px 10px;
		color: #fff;
		font-family: Lato-Regular;
		border-radius: 4px;
		width: max-content;
		margin: 0 auto;
	}

	.service-desk-status-completed {
		background-color: #62d099;
		padding: 3px 10px;
		color: #fff;
		font-family: Lato-Regular;
		border-radius: 4px;
		width: max-content;
		margin: 0 auto;
	}

	.service-desk-status-in-progress {
		background-color: #25669f;
		padding: 3px 10px;
		color: #fff;
		font-family: Lato-Regular;
		border-radius: 4px;
		width: max-content;
		margin: 0 auto;
	}

	.service-desk-status-pending {
		background-color: #8a7153;
		padding: 3px 10px;
		color: #fff;
		font-family: Lato-Regular;
		border-radius: 4px;
		width: max-content;
		margin: 0 auto;
	}

	.service-desk-status-declined {
		background-color: #c73f64;
		padding: 3px 10px;
		color: #fff;
		font-family: Lato-Regular;
		border-radius: 4px;
		width: max-content;
		margin: 0 auto;
	}

	.service-desk-status-review {
		background-color: #ff8c00;
		padding: 3px 10px;
		color: #fff;
		font-family: Lato-Regular;
		border-radius: 4px;
		width: max-content;
		margin: 0 auto;
	}

	.service-desk-status-pending-compliance-approval {
		background-color: #48cae4;
		padding: 3px 10px;
		color: #fff;
		font-family: Lato-Regular;
		border-radius: 4px;
		width: max-content;
		margin: 0 auto;
	}

	.priority-indicator-High {
		color: red !important;
		font-family: "Lato-Bold";
	}

	.text-align-left {
		text-align: left !important;
	}

	.priority-indicator-Low {
		color: green !important;
		font-family: "Lato-Bold";
	}

	.priority-indicator-Medium {
		color: #deb700 !important;
		font-family: "Lato-Bold";
	}

	.manage-appointment-status-upcoming {
		background-color: #25669f;
		padding: 3px 10px;
		color: #fff;
		font-family: Lato-Regular;
		border-radius: 4px;
		width: max-content;
		margin: 0 auto;
	}

	.manage-appointment-status-canceled {
		background-color: #c14242;
		padding: 3px 10px;
		color: #fff;
		font-family: Lato-Regular;
		border-radius: 4px;
		width: max-content;
		margin: 0 auto;
	}

	.manage-appointment-status-completed {
		background-color: #86c142;
		padding: 3px 10px;
		color: #fff;
		font-family: Lato-Regular;
		border-radius: 4px;
		width: max-content;
		margin: 0 auto;
	}

	.manage-appointment-status-upcoming {
		background-color: #25669f;
		padding: 3px 10px;
		color: #fff;
		font-family: Lato-Regular;
		border-radius: 4px;
		width: max-content;
		margin: 0 auto;
	}

	.manage-appointment-status-canceled {
		background-color: #c14242;
		padding: 3px 10px;
		color: #fff;
		font-family: Lato-Regular;
		border-radius: 4px;
		width: max-content;
		margin: 0 auto;
	}

	.manage-appointment-status-completed {
		background-color: #86c142;
		padding: 3px 10px;
		color: #fff;
		font-family: Lato-Regular;
		border-radius: 4px;
		width: max-content;
		margin: 0 auto;
	}
}

.priority-indicator-High {
	color: red !important;
	font-family: "Lato-Bold";
}

.priority-indicator-Low {
	color: green !important;
	font-family: "Lato-Bold";
}

.priority-indicator-Medium {
	color: #deb700 !important;
	font-family: "Lato-Bold";
}

.gs-job-dashboard-red-cell-background {
	background-color: #f00000 !important;
	//	color: white;
}

.gs-job-dashboard-green-cell-background {
	background-color: #00f000 !important;
	//color: white;
}

.gs-job-dashboard-yellow-cell-background {
	background-color: #fcf403 !important;
	//color: white;
}

.gs-job-dashboard-default-cell-background {
	background-color: #ffffff !important;
	//color: white;
}

app-overdraft-reports-sidebar {
	.dx-item.dx-treeview-item {
		background-color: transparent !important;
		color: #024b81 !important;
		padding-bottom: 0;
	}

	.overdraft-report-sidebar-list {
		display: grid;
	}

	.overdraft-report-sidebar-list ul li {
		margin-top: 0px !important;
	}

	.dx-texteditor.dx-editor-outlined {
		margin-bottom: 10px;
	}

	.dx-treeview-scrollable {
		overflow: unset !important;
	}
}

.custom-data-grid {
	max-height: calc(100vh - 46vh);
}

.overdraft-report .title-shadow-box {
	width: 100%;
	padding: 8px 20px 12px;
	box-shadow: 0px 3px 6px #00000029;
	border-radius: 13px;
	background-color: #fff;
}

app-donut-chart {
	.pies-container {
		margin: auto;
		//width: 800px;
	}

	.pies-container > .pie {
		width: 400px;
		margin-top: -50px;
		//float: left;
	}

	.long-title h3 {
		font-weight: 200;
		font-size: 28px;
		text-align: center;
		margin-bottom: 20px;
	}
}

.excel-export-icon {
	border: 1px solid #024b81;
	background: #f6f9ff;
	padding: 5px 20px;
	border-radius: 6px;
	color: #024b81;
	font-family: "Lato-Bold";
	font-weight: 600;
	margin-right: 11px;
	vertical-align: middle;
}

.excel-export-icon i {
	font-size: 20px;
	font-weight: bold;
	color: green;
	margin-right: 10px;
	vertical-align: top;
}

/* Referral Snooze Modal */
.referral-snooze-modal .modal-content {
	background-color: #fff !important;
	border-color: transparent;
}

.referral-snooze-modal .modal-body {
	padding: 0 !important;
}

.referral-snooze-modal .modal-body .close-btn {
	color: #25669f;
	border: none;
	background-color: transparent;
	font-size: 22px;
}

.referral-snooze-modal .align-close-icon {
	text-align: end;
	padding-right: 2px;
}

.referral-snooze-modal .modal-headerr {
	background-color: #fff;
	/* color: #fff; */
	padding: 5px 5px;
	border-radius: 0.3rem 0.3rem 0 0;
}

.referral-snooze-modal .referral-snooze-main {
	// text-align: center;
	padding: 0 5px 25px;
}

.referral-snooze-modal .referral-snooze-main .referral-snooze-text {
	font-family: Lato-Medium;
	font-size: 20px;
	margin-bottom: 10px !important;
	color: #303030;
}

.referral-snooze-modal .referral-snooze-main .referral-snooze-input {
	box-shadow: 0px 3px 6px #00000029;
	border: 1px solid #707070;
	// margin: auto;
}

.referral-snooze-modal .error-text.hasError {
	color: red;
	display: block;
}

.referral-snooze-modal .submit-btn-div {
	margin-top: 24px;
	text-align: center;
}

.referral-snooze-main h4 {
	font-size: 28px;
	font-weight: 400;
	text-align: center;
	color: #25669f;
}

.container-fluid.container-flex-fluid.custom-height {
	min-height: 500px !important;
}

.gsJobDashboard-height {
	height: 84% !important;
}

.column-chooser-btn {
	border: 1px solid #024b81;
	background: #f6f9ff;
	padding: 6px 11px 6px;
	border-radius: 6px;
	color: #024b81;
	font-family: "Lato-Bold";
	font-weight: 600;
	margin-left: 11px;
}

.column-chooser-btn i {
	font-size: 21px;

	margin-right: 10px;
	vertical-align: top;
}

.release-event-icon {
	color: #c2b9b9;
	cursor: pointer;
	margin-left: 5px;
	font-size: 17px;
	font-family: Lato-Bold;
}

.release-event-icon img {
	margin-left: 15px;
	margin-right: 12px;
	vertical-align: inherit;
	width: 21px;
}

// app-vehicle-lien-dashboard {
// 	.empty-vehicle-lien {
// 		margin-left: 50px;
// 	}
// }

.status-current {
	color: #15a523;
	vertical-align: super;
}

.status-released {
	color: #c2b9b9;
	vertical-align: super;
}

.vehicle-lien-dashboard-red-cell-background {
	background-color: #f00000 !important;
	//	color: white;
}

.release-event-edit-icon {
	color: #c2b9b9;
	cursor: pointer;
	margin-left: 5px;
	font-size: 17px;
	font-family: Lato-Bold;
}

.release-event-edit-icon img {
	margin-right: 3px;

	vertical-align: inherit;
	width: 17px;
	height: 15px;

	vertical-align: initial;
}

.vehicle-details .status-indicator {
	padding: 5px 10px;
	/* padding: 2px 10px 5px; */
	color: #fff;
	vertical-align: sub;
	/* vertical-align: middle; */
	font-family: Lato-Regular;
	border-radius: 4px;
	border: 1px solid;
	margin-left: 5px;

	&.LIEN_REQUESTED {
		background-color: #8a7153;
		border-color: #8a7153;
	}

	&.InProgress {
		background-color: $refstatus-in-progress;
		border-color: $refstatus-in-progress;
	}

	&.RELEASED {
		background-color: #62d099;
		border-color: #62d099;
	}

	&.REQUEST_DENIED {
		background-color: #c73f64;
		border-color: #c73f64;
	}

	&.LIEN_FILED {
		background-color: #48cae4;
		border-color: #48cae4;
	}

	&.RELEASE_REQUEST {
		background-color: #25669f;
		border-color: #25669f;
	}

	&.RELEASE_DENIED {
		background-color: #e3a257;
		border-color: #e3a257;
	}
}

app-generate-qr-code {
	.dx-texteditor-container {
		border: $trb-blue 1px solid;
	}
}

.custom-row-request-denied {
	background-color: #c73f64;
	text-align: center;
	padding: 3px 10px;
	color: white;
	border-radius: 4px;
	width: max-content;
	margin: 0 auto;
}

.custom-row-lien-requested {
	background-color: #8a7153;
	padding: 3px 10px;
	color: #fff;
	font-family: Lato-Regular;
	border-radius: 4px;
	width: max-content;
	margin: 0 auto;
}

.custom-row-released {
	background-color: #62d099;
	text-align: center;
	padding: 3px 10px;
	color: white;
	border-radius: 4px;
	width: max-content;
	margin: 0 auto;
}

.custom-row-release-request {
	background-color: #25669f;
	padding: 3px 10px;
	color: #fff;
	font-family: Lato-Regular;
	border-radius: 4px;
	width: max-content;
	margin: 0 auto;
}

.custom-row-lien-filed {
	background-color: #48cae4;
	padding: 3px 10px;
	color: #fff;
	font-family: Lato-Regular;
	border-radius: 4px;
	width: max-content;
	margin: 0 auto;
}

.custom-row-release-denied {
	background-color: #e3a257;
	padding: 3px 10px;
	color: #fff;
	font-family: Lato-Regular;
	border-radius: 4px;
	width: max-content;
	margin: 0 auto;
}

.custom-row-pending-approval {
	background-color: #8a7153;
	padding: 3px 10px;
	color: #fff;
	font-family: Lato-Regular;
	border-radius: 4px;
	width: max-content;
	margin: 0 auto;
}

.custom-row-pending-travel-details {
	background-color: #48cae4;
	padding: 3px 10px;
	color: #fff;
	font-family: Lato-Regular;
	border-radius: 4px;
	width: max-content;
	margin: 0 auto;
}

.custom-row-pending-travel-booking {
	background-color: #25669f;
	padding: 3px 10px;
	color: #fff;
	font-family: Lato-Regular;
	border-radius: 4px;
	width: max-content;
	margin: 0 auto;
}

.custom-row-approved {
	background-color: #62d099;
	padding: 3px 10px;
	color: #fff;
	font-family: Lato-Regular;
	border-radius: 4px;
	width: max-content;
	margin: 0 auto;
}

.custom-row-denied {
	background-color: #c73f64;
	padding: 3px 10px;
	color: #fff;
	font-family: Lato-Regular;
	border-radius: 4px;
	width: max-content;
	margin: 0 auto;
}

.custom-row-cancelled {
	background-color: #e3a257;
	padding: 3px 10px;
	color: #fff;
	font-family: Lato-Regular;
	border-radius: 4px;
	width: max-content;
	margin: 0 auto;
}

.btn-cancel-booking {
	background-color: #8a7153 !important;
	color: var.$white !important;
}

.btn-cancel-booking:hover {
	color: var.$white !important;
}

.btn-deny {
	background-color: #ff0000 !important;
	color: var.$white !important;
}

.btn-deny:hover {
	color: var.$white !important;
}

.travel-request-modal .error-text.hasError {
	color: red;
	display: block;
}

.referral-tab .dx-datagrid-header-panel {
	padding: 0px !important;
}

.referral-tab .dx-datagrid-header-panel {
	padding: 0px !important;
}

app-view-team-members {
	.private-banking-form .dx-accordion-item {
		background-color: #f6f9ff;
		font-size: 18px;
		margin-bottom: 5px;
	}

	.private-banking-form .dx-accordion-item:hover {
		background-color: transparent;
	}

	.private-banking-form .dx-accordion-item-title {
		color: #024b81;
	}

	.private-banking-form .dx-accordion-item-body {
		padding: 0 12px;
		margin-left: 25px;
	}

	.private-banking-form .dx-accordion-item-title::before {
		display: none;
	}
}

app-relationship-details {
	.relationship-details .dx-accordion-item {
		margin-bottom: 10px;
	}

	.relationship-details .dx-accordion-item-title .dx-icon {
		font-size: 15px;
		color: #fff;
	}

	.relationship-details .dx-accordion-item:hover {
		background-color: transparent;
	}

	.relationship-details .dx-accordion-item-title {
		color: #024b81;
		border: 1px solid #024b81;
		background-color: #f6f9ff;
		height: 28px;
	}

	.relationship-details .dx-accordion-item-body {
		box-shadow: 0px 4px 6px #00000029;
		border: 1px solid #b7b7b7;
		height: fit-content;
		text-align: initial;
		width: 100%;
		border-radius: 8px;
		background-color: #fff;
		position: relative;
	}

	.relationship-details .dx-accordion-item-title::before {
		display: none;
	}
}

/* Birthday widget*/
.widget-title-sec {
	padding: 2px 12px;
	background-color: #024b81;
	border-top-left-radius: 13px;
	border-top-right-radius: 13px;
}

.widget-title {
	color: white;
	font-family: Lato-Regular;
	font-size: 1.2rem;
}

.birthday-list-sec {
	padding-inline: 12px;
	max-height: 163px;
	overflow-y: auto;
	margin-bottom: 5px;
}

.birthday-streamer {
	background-image: url("../src/assets/images/birthday-streamer.png");
	background-size: cover;
	background-position-y: -63px;
	height: 36px;
}

.anniversary-streamer {
	background-image: url("../src/assets/images/anniversary-streamer.jpg");
	background-size: cover;
	background-position-y: -63px;
	height: 36px;
}

.sub-sec-title {
	margin-top: 5px;
}

.sub-sec-title span {
	font-family: Lato-Bold;
	font-size: 14px;
	text-transform: uppercase;
	color: #4a4a4a;
}

.sub-sec-content {
	display: flex;
	gap: 12px;
	flex-wrap: wrap;
}

.bottom-border {
	border-bottom: 2px solid #024b81;
	padding-bottom: 10px;
	gap: 7px 0px;
}

.upcoming-content {
	gap: 7px 0px;
}

.birtday-item-fbox {
	display: flex;
	gap: 3px;
	align-self: center;
	cursor: pointer;
}

.no-birthday-item-container {
	display: flex;
	background: #f5f7f8;
	border: 1px dotted #f5f7f8;
	border-radius: 10px;
	margin-bottom: 0.4rem;
	width: 90%;
	margin: auto;

	&::after {
		content: "No Birthdays Today!";
		color: #e0e6e0;
		display: flex;
		flex-grow: 1;
		justify-content: center;
		align-items: center;
		font-size: 29px;
		letter-spacing: 5px;
		font-family: "lato" sans-serif;
	}
}

.no-birthday-upcoming-item-container {
	display: flex;
	background: #f5f7f8;
	border: 1px dotted #f5f7f8;
	border-radius: 10px;
	margin-bottom: 0.4rem;
	width: 90%;
	margin: auto;

	&::after {
		content: "No Upcoming Birthdays!";
		color: #e0e6e0;
		display: flex;
		flex-grow: 1;
		justify-content: center;
		align-items: center;
		font-size: 29px;
		letter-spacing: 5px;
		font-family: "lato" sans-serif;
	}
}

.no-anniversary-item-container {
	display: flex;
	background: #f5f7f8;
	border: 1px dotted #f5f7f8;
	border-radius: 10px;
	margin-bottom: 0.4rem;
	width: 90%;
	margin: auto;

	&.today::after {
		content: "No Anniversaries Today!";
		color: #e0e6e0;
		display: flex;
		flex-grow: 1;
		justify-content: center;
		align-items: center;
		font-size: 29px;
		letter-spacing: 5px;
		font-family: "lato" sans-serif;
	}

	&.upcoming::after {
		content: "No Upcoming Anniversaries!";
		color: #e0e6e0;
		display: flex;
		flex-grow: 1;
		justify-content: center;
		align-items: center;
		font-size: 29px;
		letter-spacing: 5px;
		font-family: "lato" sans-serif;
	}
}

.profile-img img {
	border-radius: 50%;
	width: 45px;
	object-fit: cover;
	height: 45px;
}

.birtday-profile-details {
	align-content: center;
}

.birthday-name {
	color: #024b81;
	font-family: Lato-Regular;
	font-size: 14px;
	cursor: pointer;
	margin-bottom: 0;
	line-height: 1rem;
}

.birthday-name:hover {
	color: #024b81;
	text-decoration: underline;
}

.birth-date {
	font-size: 14px;
	font-family: Lato-Regular;
	color: #8d8d8d;
	margin-bottom: 0;
}

.home-widget-container {
	margin-left: 1rem;
	margin-top: 1rem;
	// padding-bottom: 1rem;
}

.home-widget-container .title-shadow-box {
	width: 100%;
	padding: 0 0 0px;
	box-shadow: 0px 3px 6px #00000029;
	border-radius: 13px;
	background-color: #fff;
	// margin-bottom: 16px;
	// margin-top: 20px;
	border: 1px solid #b5c6f2 !important;
}

.travel-request-checkbox {
	padding-top: 25px !important;
	text-align: right;
}

app-travel-request-confirmation-modal {
	.dx-checkbox-text {
		width: fit-content !important;
		white-space: nowrap !important;
	}
}

app-profile {
	.dx-checkbox-text {
		font-family: Lato-Regular !important;
		color: #686868 !important;
		font-size: 16px !important;
	}
}

app-it-dashboard {
	.chart-no-cursor .dx-template-wrapper {
		cursor: default !important;
	}
}

app-employee-birthday {
	.dx-button-mode-text {
		border-radius: 50%;
	}

	.dx-button-mode-text .dx-icon {
		color: #ececec;
	}

	.high-light-color,
	.high-light-color:hover {
		background-color: #ececec !important;

		.dx-icon {
			color: #1c669d !important;
		}
	}

	.dx-button-mode-text:hover {
		background-color: #1c669d;
		border-radius: 50%;

		.dx-icon {
			color: #ececec;
		}
	}
}

app-add-to-relationship-dialog {
	.dx-radiogroup .dx-radiobutton {
		margin-top: 0;
		margin-bottom: 0;
	}
}

.dx-context-menu .dx-submenu ul {
	width: 100% !important;
}

@media screen and (min-height: 950px) {
	// .referral-form .title-shadow-box {
	// 	//min-height: 79vh !important;
	// }

	.custom-data-grid {
		min-height: 68vh !important;
	}
}

.hidden {
	display: none;
}

/* */
app-overdraft-reports-sidebar {
	.dx-treeview-item-container {
		margin-left: -30px;
	}
}

app-overdraft-program-reports {
	.gridTitle {
		font-size: 28px;
		font-family: "Segoe UI Light", "Helvetica Neue Light", "Segoe UI", "Helvetica Neue", "Trebuchet MS", Verdana,
			sans-serif;
		font-weight: 500;
		fill: rgb(51, 51, 51);
	}
}

app-overdraft-reports-filter {
	.white-bg-box {
		background-color: #fff;
		padding: 10px;
		box-shadow: 0px 3px 6px #00000029;
		border-radius: 10px;
		margin-bottom: 15px;
	}

	a {
		cursor: pointer;
	}

	.odp-form {
		margin-bottom: 16px;
	}

	.odp-form .title-shadow-box {
		width: 100%;
		padding: 12px 20px;
		box-shadow: 0px 3px 6px #00000029;
		border-radius: 13px;
		background-color: #fff;
		min-height: 70vh;
		margin-bottom: 16px;
	}

	.odp-form .tab-filters {
		display: flex;
		flex-wrap: wrap;
	}

	.odp-form .tab-btn {
		height: 40px;
		display: flex;
		align-items: center;
		border: 1px solid #003c69;
		justify-content: center;
		border-radius: 5px;
		margin: 10px;
		width: 150px;
		background-color: #003c69;
	}

	.odp-form .tab-btn span {
		color: #fff;
		font-family: Lato-Medium;
	}

	.odp-form .region-branch-outerbox {
		background-color: #fff;
		border: 1px solid #c2c2c2;
		padding: 0 10px 8px;
		border-radius: 4px;
	}

	.filter-fieldset-sec .legend-style {
		float: none;
		width: auto;
		margin-bottom: -20px;
		padding: 0 6px;
		border-radius: 5px;
		font-size: 16px;
		border: 1px solid #707070;
		background-color: #fff;
		color: #303030;
		font-family: Lato-Bold;
		margin-left: 16px;
	}

	.filter-fieldset-sec .fieldset-style {
		border: 1px solid #919191;
		padding: 26px 12px 10px;
		/* display: flex; */
		width: 100%;
		border-radius: 7px;
		/* flex-wrap: wrap; */
		background-color: #f5f5f5;
	}

	.filter-fieldset-sec .filter-f-box {
		display: flex;
		align-items: center;
		margin-right: 16px;
		margin-top: 10px;
	}

	.odp-form .bulk-action-btn {
		padding: 3px 14px;
		font-size: 12px;
		margin: 3px 3px 10px;
		margin-inline: 0;
		color: #fff;
		background-color: #003c69;
		border-radius: 20px;
		border: none;
		margin-right: 5px;
	}

	.odp-form .region-btn-sec {
		height: 120px;
		overflow-y: auto;
	}

	.odp-form .branch-btn-sec {
		height: 84px;
		overflow-y: auto;
	}

	.odp-form .region-btn-fbox,
	.odp-form .branch-btn-fbox {
		display: flex;
		flex-wrap: wrap;
		gap: 8px;
	}

	.odp-form .region-button-partial {
		background-color: var.$trb-gold !important;
		color: #fff !important;
		border-radius: 4px;
		/* width: 100%;
    height: 100%; */
		border: 1px solid var.$trb-gold;
		font-size: 12px;
		padding: 0;
		white-space: wrap;
		min-width: 120px;
		height: 35px;
		padding-inline: 8px;
		font-family: Lato-Medium;
	}

	.odp-form .region-btn-selected {
		background-color: #003c69 !important;
		color: #fff !important;
		border-radius: 4px;
		/* width: 100%;
    height: 100%; */
		border: 1px solid #003c69;
		font-size: 12px;
		padding: 0;
		white-space: wrap;
		min-width: 120px;
		height: 35px;
		font-family: Lato-Medium;
		padding-inline: 8px;
	}

	.odp-form .branch-btn-selected {
		height: 35px;
		min-width: 140px;
		background-color: #003c69 !important;
		color: #fff !important;
		border-radius: 4px;
		border: 1px solid #003c69;
		font-size: 12px;
		padding: 0;
		padding-inline: 8px;
		white-space: wrap;
		font-family: Lato-Medium;
	}

	.odp-form .region-btn-unselected {
		background-color: transparent !important;
		color: #003c69 !important;
		border-radius: 4px;
		/* width: 100%;
    height: 100%; */
		border: 1px solid #003c69;
		font-size: 12px;
		padding: 0;
		white-space: wrap;
		min-width: 120px;
		height: 35px;
		padding-inline: 8px;
		font-family: Lato-Medium;
	}

	.odp-form .branch-btn-unselected {
		height: 35px;
		min-width: 140px;
		background-color: transparent !important;
		color: #003c69 !important;
		border-radius: 4px;
		border: 1px solid #003c69;
		font-size: 12px;
		padding: 0;
		padding-inline: 8px;
		white-space: wrap;
		font-family: Lato-Medium;
	}

	.bulk-action-fbox-branch {
		display: flex;
		justify-content: space-between;
		align-items: baseline;
	}

	.odp-form .region-indication {
		font-size: 13px;
		color: #003c69;
		font-family: Lato-Regular;
	}

	.selected-branch-fieldset-sec .legend-style {
		float: none;
		width: auto;
		margin-bottom: -20px;
		padding: 0 6px;
		border-radius: 5px;
		font-size: 16px;
		border: 1px solid #707070;
		background-color: #fff;
		color: #303030;
		font-family: Lato-Bold;
		margin-left: 16px;
	}

	.selected-branch-fieldset-sec .fieldset-style {
		border: 1px solid #919191;
		padding: 26px 12px 10px;
		/* display: flex; */
		width: 100%;
		border-radius: 7px;
		/* flex-wrap: wrap; */
		background-color: #fff;
	}

	.filter-fieldset-sec .filter-f-box {
		display: flex;
		align-items: center;
		margin-right: 16px;
		margin-top: 10px;
	}

	.odp-form .selected-branch-fieldset-sec .selected-branch-sec {
		height: 174px;
		overflow-y: auto;
	}

	.bubble-display {
		background-color: #f2dcb5;
		border: none;
		color: #000000;
		padding: 2px 10px;
		font-size: 15px;
		font-family: Lato-Regular;
		border-radius: 24px;
		display: inline-block;
		/* margin-top:6px; */
		margin-bottom: 6px;
		/* margin-right:9px; */
		display: flex;
		gap: 10px;
		align-items: center;
		width: fit-content;
	}

	.bubble-display .close-bubble {
		color: #8a7153;
		/* margin-left: 12px; */
	}

	.filter-tab-btn {
		display: inline-block;
		height: 40px;
		display: flex;
		align-items: center;
		border: 1px solid var.$trb-blue;
		justify-content: center;
		border-radius: 5px;
		margin: 10px;
		width: 150px;

		& :hover,
		& :focus {
			cursor: pointer;
		}

		& .tab-header {
			font-family: "Lato", sans-serif;
			color: var.$trb-blue;
			font-size: 16px;
		}

		& .tab-icon {
			padding-right: 5px;
			color: var.$trb-blue;
			font-size: 1.5rem !important;
		}
	}

	.tab-selected {
		background-color: var.$trb-blue;

		span,
		.icon,
		.tab-header,
		.tab-icon {
			color: white !important;
		}
	}

	.tab-selected:hover {
		background-color: var.$trb-blue;
	}

	.tab-unselected:hover {
		background-color: var.$unselected-grey-background;
	}

	@media screen and (min-width: 991px) and (max-width: 1130px) {
		.selected-branch-fieldset-sec .legend-style {
			margin-left: 5px;
		}
	}

	@media screen and (min-width: 1131px) and (max-width: 1381px) {
		.selected-branch-fieldset-sec .legend-style {
			margin-left: 8px;
		}
	}

	@media screen and (min-width: 991px) and (max-width: 1050px) {
		.selected-branch-fieldset-sec .legend-style {
			font-size: 13px;
		}

		.filter-fieldset-sec .legend-style {
			font-size: 13px;
		}
	}

	@media screen and (min-width: 1029px) and (max-width: 1279px) {
		.odp-form .branch-btn-sec {
			height: 120px;
			overflow-y: auto;
		}
	}

	@media screen and (min-width: 1690px) {
		.odp-form .branch-btn-sec {
			height: 120px;
		}
	}

	@media screen and (min-width: 1280px) and (max-width: 1322px) {
		.odp-form .branch-btn-sec {
			height: 120px;
		}
	}

	@media screen and (min-width: 1280px) and (max-width: 1342px) {
		.odp-form .selected-branch-fieldset-sec .selected-branch-sec {
			height: 210px;
		}
	}

	@media screen and (min-width: 1051px) and (max-width: 1279px) {
		.odp-form .selected-branch-fieldset-sec .selected-branch-sec {
			height: 186px;
		}
	}

	@media screen and (min-width: 992px) and (max-width: 1050px) {
		.odp-form .selected-branch-fieldset-sec .selected-branch-sec {
			height: 192px;
		}
	}
}

app-employee-birthday {
	.dx-button-mode-text {
		border-radius: 50%;
	}

	.dx-button-mode-text .dx-icon {
		color: #ececec;
	}

	.high-light-color,
	.high-light-color:hover {
		background-color: #ececec !important;

		.dx-icon {
			color: #1c669d !important;
		}
	}

	.dx-button-mode-text:hover {
		background-color: #1c669d;
		border-radius: 50%;

		.dx-icon {
			color: #ececec;
		}
	}
}

.dx-context-menu .dx-submenu ul {
	width: 100% !important;
}

.hidden {
	display: none;
}

app-profile {
	.profile-container-sec .form-content-container {
		margin-right: 0 !important;
	}
}

app-multiple-panes-graph {
	#chart {
		height: 100% !important;
	}

	.chart-settings-button {
		position: relative;
		right: 50px;
		top: 8.5px;
		float: right;
		height: 34px;
		width: 34px;
		background-color: transparent;
		border: solid 1px #dddddd;
		border-radius: 3.5px;
		font-size: 25px;
		display: flex;
		justify-content: center;
		align-items: center;
		color: #333333;
		z-index: 3;
		margin-bottom: -34px;
	}

	.chart-settings-button:hover {
		background-color: #e6e6e6;
	}
}

.mat-mdc-menu-panel {
	background-color: white !important;
}

app-side-by-side-bar-graph,
app-stacked-graph,
app-line-graph {
	#chart {
		height: 100% !important;
	}

	.chart-settings-button {
		position: relative;
		right: 50px;
		top: 8.5px;
		float: right;
		height: 34px;
		width: 34px;
		background-color: transparent;
		border: solid 1px #dddddd;
		border-radius: 3.5px;
		font-size: 25px;
		display: flex;
		justify-content: center;
		align-items: center;
		color: #333333;
		z-index: 3;
		margin-bottom: -34px;
	}

	.chart-settings-button:hover {
		background-color: #e6e6e6;
	}
}

@media screen and (min-height: 800px) and (max-height: 949px) {
	.referral-form .title-shadow-box {
		min-height: 75vh !important;
	}

	.chart-settings-button {
		position: relative;
		right: 50px;
		top: 8.5px;
		float: right;
		height: 34px;
		width: 34px;
		background-color: transparent;
		border: solid 1px #dddddd;
		border-radius: 3.5px;
		font-size: 25px;
		display: flex;
		justify-content: center;
		align-items: center;
		color: #333333;
		z-index: 3;
		margin-bottom: -34px;
	}

	.chart-settings-button:hover {
		background-color: #e6e6e6;
	}

	.custom-data-grid {
		min-height: 62vh !important;
	}
}

app-loan-maturing-dashboard {
	.dx-datagrid-revert-tooltip {
		display: none;
	}
}

app-loan-maturing {
	.loan-maturity-container .form-maturity {
		margin-right: 0 !important;
	}

	.loan-maturity-container .layout-container-withMargin {
		margin-right: 1rem !important;
	}
}

.deposit-account-popup .dx-popup-content {
	padding: 12px !important;
}

.deposit-popup-treeView .dx-treeview-node {
	margin-top: 0px !important;
}

//date icon styles
.dx-datebox-datetime .dx-dropdowneditor-icon::before {
	content: "\f026";
}

//grid sort icon styles
.dx-datagrid-headers .dx-datagrid-table .dx-row > td .dx-sort {
	color: #fff;
}

.modal-ok-btn:hover {
	box-shadow:
		0px 3px 1px -2px rgba(0, 0, 0, 0.2),
		0px 2px 2px 0px rgba(0, 0, 0, 0.14),
		0px 1px 5px 0px rgba(0, 0, 0, 0.12) !important;
}

app-loan-maturing-dashboard {
	.dx-datagrid-revert-tooltip {
		display: none;
	}
}

app-loan-maturing {
	.loan-maturity-container .form-maturity {
		margin-right: 0 !important;
	}

	.loan-maturity-container .layout-container-withMargin {
		margin-right: 1rem !important;
	}
}

.added-custom-height {
	height: auto !important;
}

app-mortgage-referral-dashboard,
app-wealth-referral-dashboard,
app-treasury-referral-dashboard,
app-merchant-referral-dashboard,
app-loan-referral-dashboard,
app-international-private-client-referral-dashboard,
app-insurance-referral-dashboard,
app-fx-referral-dashboard,
app-employee-referral-dashboard,
app-domestic-private-client-referral-dashboard,
app-deposit-account-referral-dashboard,
app-credit-card-referral-dashboard,
app-branch-admin-dashboard,
app-facilities-dashboard,
app-marketing-dashboard,
app-view-to-do-list,
app-view-user-referral,
app-relationship-details,
app-view-team-members,
app-account-application-decisioning-summary,
app-fx-edit,
app-insurance-edit,
app-credit-card-edit,
app-deposit-account-edit,
app-domestic-private-client-edit,
app-employee-edit,
app-international-private-client-edit,
app-loan-edit,
app-merchant-edit,
app-mortgage-edit,
app-treasury-edit,
app-wealth-edit,
app-branch-admin-service-ticket-edit,
app-facilities-service-ticket-edit,
app-marketing-service-ticket-edit,
app-fx-referral,
app-credit-card-referral,
app-deposit-account-referral,
app-domestic-private-client-referral,
app-employee-referral,
app-insurance-referral,
app-international-private-client-referral,
app-loan-referral,
app-merchant,
app-mortgage,
app-treasury,
app-wealth,
app-branch-admin-service-ticket-add,
app-facilities-service-ticket-add,
app-marketing-service-ticket-add,
app-overdraft-rules,
app-view-tickets,
app-it-dashboard,
app-lookup,
app-branch-create,
app-vehicle-lien-details,
app-vendor-dashboard,
app-add-edit-vendor,
app-to-do-details,
app-gs-job-type-create,
app-gs-job-create,
app-manage-appointment {
	.layout-container-withMargin {
		margin-left: 1.3rem !important;
		margin-right: 0.1rem !important;
	}
}

app-campaign-management-dashboard {
	//when scroller appears
	@media screen and (max-height: 844px) {
		.layout-container-withMargin {
			margin-right: 1rem !important;
		}
	}

	//scroller wont appear
	@media screen and (min-height: 845px) {
		.layout-container-withMargin {
			margin-right: 2rem !important;
		}
	}
}

app-travel-request-dashboard {
	@media screen and (max-height: 859px) {
		.layout-container-withMargin {
			margin-right: 1rem !important;
		}
	}

	//scroller wont appear
	@media screen and (min-height: 860px) {
		.layout-container-withMargin {
			margin-right: 2rem !important;
		}
	}
}

app-overdraft-program-dashboard {
	.layout-container-withMargin {
		margin-right: 2rem !important;
	}
}

app-service-desk-dashboard {
	.layout-container-withMargin {
		margin-right: 2rem !important;
	}
}

app-loan-maturing-dashboard {
	.dx-datagrid-revert-tooltip {
		display: none;
	}
}

app-loan-maturing {
	.loan-maturity-container .form-maturity {
		margin-right: 0 !important;
	}

	.loan-maturity-container .layout-container-withMargin {
		margin-right: 1rem !important;
	}
}

app-loan-maturing-dashboard {
	.dx-datagrid-revert-tooltip {
		display: none;
	}
}

app-loan-maturing {
	.loan-maturity-container .form-maturity {
		margin-right: 0 !important;
	}

	.loan-maturity-container .layout-container-withMargin {
		margin-right: 1rem !important;
	}
}

.btn-send-vendor {
	background-color: #8a7153 !important;
	color: var.$white !important;
}

.btn-send-vendor:hover {
	color: var.$white !important;
}

/* Loader css start */
.default-app-loader-container {
	position: relative;
	top: 150px;
}

#wrapper {
	width: 100%;
	max-width: 725px;
	margin: 0px auto;
}

.gears {
	width: 48%;
	display: inline-block;
}

.gears:first-child {
	margin-right: 3%;
}

.gears-container {
	width: 150px;
	height: 150px;
	font-size: 24px;
	padding: 9%;
	position: relative;
	margin: 0px auto;
}

.gear-rotate {
	width: 32px;
	height: 32px;
	top: 50%;
	left: 50%;
	margin-top: -16px;
	margin-left: -16px;
	background: #1c4595;
	position: absolute;
	border-radius: 16px;
	-webkit-animation: 1s gear-rotate linear infinite;
	-moz-animation: 1s gear-rotate linear infinite;
	animation: 1s gear-rotate linear infinite;
}

.gear-rotate::before {
	width: 44.8px;
	height: 44.8px;
	background:
		-webkit-linear-gradient(0deg, transparent 39%, #1c4595 39%, #1c4595 61%, transparent 61%),
		-webkit-linear-gradient(60deg, transparent 42%, #1c4595 42%, #1c4595 58%, transparent 58%),
		-webkit-linear-gradient(120deg, transparent 42%, #1c4595 42%, #1c4595 58%, transparent 58%);
	background:
		-moz-linear-gradient(0deg, transparent 39%, #1c4595 39%, #47ec19 61%, transparent 61%),
		-moz-linear-gradient(60deg, transparent 42%, #1c4595 42%, #1c4595 58%, transparent 58%),
		-moz-linear-gradient(120deg, transparent 42%, #1c4595 42%, #1c4595 58%, transparent 58%);
	background:
		-o-linear-gradient(0deg, transparent 39%, #1c4595 39%, #1c4595 61%, transparent 61%),
		-o-linear-gradient(60deg, transparent 42%, #1c4595 42%, #1c4595 58%, transparent 58%),
		-o-linear-gradient(120deg, transparent 42%, #47ec19 42%, #1c4595 58%, transparent 58%);
	background:
		-ms-linear-gradient(0deg, transparent 39%, #1c4595 39%, #1c4595 61%, transparent 61%),
		-ms-linear-gradient(60deg, transparent 42%, #1c4595 42%, #1c4595 58%, transparent 58%),
		-ms-linear-gradient(120deg, transparent 42%, #1c4595 42%, #1c4595 58%, transparent 58%);
	background: linear-gradient(0deg, transparent 39%, #1c4595 39%, #1c4595 61%, transparent 61%),
		linear-gradient(60deg, transparent 42%, #1c4595 42%, #1c4595 58%, transparent 58%),
		linear-gradient(120deg, transparent 42%, #1c4595 42%, #1c4595 58%, transparent 58%);
	position: absolute;
	content: "";
	top: -6.4px;
	left: -6.4px;
	border-radius: 22.4px;
}

.gear-rotate::after {
	width: 16px;
	height: 16px;
	background: #fff;
	position: absolute;
	content: "";
	top: 8px;
	left: 8px;
	border-radius: 8px;
}

.loading-text-sec {
	text-align: center;
}

.loading-text {
	position: absolute;
	top: 70%;
	left: 33%;
	font-size: 14px;
}

@-webkit-keyframes gear-rotate {
	0% {
		-webkit-transform: rotate(0deg);
	}

	100% {
		-webkit-transform: rotate(360deg);
	}
}

@-moz-keyframes gear-rotate {
	0% {
		transform: rotate(0deg);
	}

	100% {
		transform: rotate(360deg);
	}
}

@keyframes gear-rotate {
	0% {
		transform: rotate(0deg);
	}

	100% {
		transform: rotate(360deg);
	}
}

/* loader css End */

//wrap header text
.dx-header-row .dx-text-content-alignment-left,
.dx-header-row .dx-text-content-alignment-right {
	white-space: normal !important;
}

app-pivot-grid {
	.dx-pivotgrid .dx-pivotgrid-area td {
		padding: 4px 6.4px !important;
	}

	.overdraft-pivot-grid .dx-pivotgrid-horizontal-headers {
		background-color: #024b81 !important;
	}

	.overdraft-pivot-grid .dx-pivotgrid-horizontal-headers td {
		color: #ffffff !important;
		white-space: normal !important;
	}

	.overdraft-pivot-grid .dx-pivotgrid table {
		width: 100% !important;
	}

	.overdraft-pivot-grid .dx-pivotgrid .dx-pivotgrid-area {
		width: 100% !important;
	}

	.dx-pivotgrid.dx-overflow-hidden {
		overflow-x: auto !important;
	}
}

.system-admin-form .title-shadow-box {
	width: 100%;
	padding: 12px 0px;
	box-shadow: 0px 3px 6px #00000029;
	border-radius: 13px;
	background-color: #fff;
	min-height: 70vh;
	margin-bottom: 2rem;
}

.extra-footer-space {
	margin-bottom: 60px !important;
}

.default-footer-space {
	margin-bottom: 10px !important;
}

.dx-link.trash-icon-class.dx-icon-trash.dx-link-icon,
.dx-link.dx-link-delete.dx-icon-trash.dx-link-icon {
	color: red !important;
}

app-job,
app-workflow,
app-job-detail {
	.diagram-box .djs-palette {
		position: fixed !important;
		width: 100px !important;
	}
}

.service-desk-report .section-header {
	position: absolute;
	background-color: #ffffff;
	border: 1px solid #707070;
	border-radius: 6px;
	padding: 0px 8px;
	top: -14px;
	left: 30px;
}

.service-desk-report .section-header label {
	font-size: 15px;
	font-family: Lato-Bold;
}

.service-desk-report .section-box {
	position: relative;
	//background-color: #f5f5f5;
	//border: 1px solid #b7b7b7;
	//box-shadow: 0px 3px 6px #00000029;
	border-radius: 4px;
	padding: 0px 16px 0px;
	// margin-top: 20px;
}

app-menu-group-create {
	.dx-treelist-headers .dx-treelist-table .dx-row > td {
		color: white !important;
	}

	.dx-treelist-headers
		.dx-treelist-table
		.dx-row
		> td:hover:not(.dx-command-select):not(.dx-command-expand):not(.dx-editor-cell):not(.dx-command-edit):not(
			.dx-treelist-group-space
		) {
		color: white !important;
		background-color: #024b81 !important;
	}

	.dx-treelist-headers .dx-treelist-table .dx-row > td:hover .dx-treelist-text-content {
		color: white !important;
	}

	.dx-treelist-container .dx-sort-down::before,
	.dx-treelist-container .dx-sort-up::before {
		color: white !important;
	}
}

.hide-standalone-grid {
	visibility: hidden;
	height: 0px !important;
	display: block;
}

.service-desk-height #clientGrid {
	max-height: 50vh;
}

@media screen and (min-height: 950px) {
	.service-desk-height .custom-data-grid {
		min-height: 95% !important;
	}
}

@media screen and (min-height: 800px) and (max-height: 949px) {
	.service-desk-height .custom-data-grid {
		min-height: 95% !important;
	}
}

.grid-phoneNumber-input-field.dx-texteditor-input {
	cursor: pointer !important;
}

@media screen and (max-height: 799px) {
	.dialog-container #clientGrid {
		height: calc(100vh - 270px) !important;
	}
}

@media screen and (min-height: 800px) and (max-height: 1099px) {
	.dialog-container #clientGrid {
		height: calc(100vh - 350px) !important;
	}
}

@media screen and (min-height: 1100px) and (max-height: 1599px) {
	.dialog-container #clientGrid {
		height: calc(100vh - 390px) !important;
	}
}

@media screen and (min-height: 1600px) {
	.dialog-container #clientGrid {
		height: calc(100vh - 400px) !important;
	}
}

.date-exclamation {
	right: 60px !important;
}

.on-hover-creator {
	position: relative;
}

.on-hover-creator .creator-information .info-icon-creator {
	position: absolute;
	top: 28px;
	right: 24px;
	position: absolute;
	top: 16px;
	right: 24px;
}

.creator-information .info-icon-creator .info-icon {
	color: #0275d8;
}

.creator-information-on-hover {
	display: none;
	position: absolute;
	background: #ffffff;
	border: 1px solid #707070;
	padding: 3px 5px;
	right: 0;
	min-width: 250px;
	border-radius: 5px;
	z-index: 9;
}

.creator-information-on-hover ul {
	list-style-type: none;
	color: #707070;
	font-size: 15px;
}

.creator-information-on-hover p {
	color: #303030;
	color: #303030;
	font-weight: bold;
}

.creator-information:hover .creator-information-on-hover {
	display: block;
}

app-lookup-create {
	.dx-texteditor.dx-editor-outlined .dx-texteditor-input {
		padding: 7px 30px 6px 10px;
	}
}

app-overdraft-notice-template-management,
app-role-list,
app-title-list,
app-branch-list,
app-connected-user-list,
app-gs-job-status-dashboard,
app-gs-job-list,
app-gs-job-notification,
app-gs-job-history,
app-gs-job-content,
app-gs-job-notification-template-dashboard,
app-lookup-table-content,
app-menu-group-list,
app-permission-list,
app-user-group-list,
app-user-list,
app-account-application-decisioning-dashboard,
app-base-regional-report-dashboard,
app-email-history,
app-user-credit-list,
app-manage-appointment {
	#clientGrid {
		height: calc(100% - 30px) !important;
	}
}

.view-travel-request-grid .filter-btn-sec {
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
	align-items: center;
}

.view-travel-request-grid .filter-btn-sec .filter-opt-fbox {
	display: flex;
	flex-wrap: wrap;
}

.view-travel-request-grid .filter-btn-sec .filter-section {
	display: flex;
	/* align-items: center; */
}

.view-travel-request-grid .filter-btn-sec .right-space {
	padding-right: 22px;
}

.confirm-message-center {
	text-align: center;
}
